<template>
  <div v-if="infoList.length">
    <div v-for="(item, index) of infoList" :key="index">
      <a-form-model
        :model="item"
        :ref="'ruleForm-' + index"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <div v-if="item.type === 1">
          <a-form-model-item prop="key">
            <div class="tem-box">
              <a-input v-model="item.key" :maxLength="4" placeholder="请填写字段名称"></a-input>
              <span class="close" @click="deleteTemCard({ index })">
                <a-icon type="close" />
              </span>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="value">
            <a-input v-model="item.value" placeholder="请填写字段内容"></a-input>
          </a-form-model-item>
        </div>
        <div v-if="item.type === 2">
          <a-form-model-item prop="img_key">
            <div class="lt">图片标题</div>
            <div class="tem-box">
              <a-input
                v-model="item.img_key"
                :maxLength="4"
                placeholder="请填写图片标题"
                @change="handlerPicTitle(index)"
              ></a-input>
              <span class="close" @click="deleteTemCard({ index })">
                <a-icon type="close" />
              </span>
            </div>
          </a-form-model-item>
          <a-form-model-item prop="img_value">
            <ClassUpLoadPic
              class="w700"
              :max="5"
              :customFileList="fileLists(item)"
              @uploadChange="(e) => uploadChange(e, item, index)"
            ></ClassUpLoadPic>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import ClassUpLoadPic from './ClassUpLoadPic';
const rules = {
  key: [{ required: true, message: '请输入字段名称!', trigger: 'change' }],
  value: [{ required: true, message: '请输入字段内容!', trigger: 'change' }],
  img_key: [{ required: true, message: '请输入图片名称!', trigger: 'change' }],
  img_value: [{ required: true, message: '请上传图片!', trigger: 'change' }]
}
export default {
  props: {
    infoList: {
      type: Array,
      default: () => []
    },
  },
  components: {
    ClassUpLoadPic,
  },
  data() {
    return {
      rules,
    }
  },
  methods: {
    validateForm() {
      const arr = [];
      this.infoList.forEach((item, index) => {
        this.$refs[`ruleForm-${index}`].forEach((item) => {
          item.validate((valid) => {
            arr.push(valid);
          });
        });
      });
      if (arr.includes(false)) {
        return false;
      }
      return true;
    },
    // 初始化
    fileLists(item) {
      const list = item?.images_list ? item?.images_list?.map((img) => (
        {
          uid: img.id,
          file_resource_id: img.id,
          url: img.url,
          status: 'done',
          name: '1',
        })) : []
      return list.length ? list : []
    },
    // 上传图片
    uploadChange(value, item, index) {
      item.img_value =value.map((i) => i.file_resource_id).join(',');
      item.images_list = value.map((img) => ({
        uid: img.file_resource_id,
        id: img.file_resource_id,
        url: img.url || img?.response?.data?.url,
        status: 'done',
        name: '1' || img?.response?.data?.file_name,
      }))
      this.$refs[`ruleForm-${index}`][0].clearValidate(['img_value'])
      this.fileLists(item)
  },
  handlerPicTitle(index) {
    this.$refs[`ruleForm-${index}`][0].clearValidate(['img_key'])
  },
  deleteTemCard({ index }) {
    this.infoList.splice(index, 1)
  },
},
}
</script>
<style lang="scss" scoped>
.tem-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close {
  margin-left: 81px;
}
</style>
