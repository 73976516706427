<template>
  <div class="offlineAppSign">
    <div class="searchForm">
      <div class="item">
        <span>辅导业务：</span>
        <a-select
          show-search
          :filter-option="filterOption"
          v-model="trainingId"
          style="width: 160px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of businessList"
            :key="item.training_id"
            :value="item.training_id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
      <div class="row row-left">
        <div class="col col-left">
          <span class="title">组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :disabled="readOnly"
            width="200px"
            v-model="orgIds"
            :disable-merge-to-parent="true"
          ></SelectOrg>
        </div>
      </div>
      <div class="item">
        <span>申请时间：</span>
        <a-range-picker @change="chooseTime" :value="[startDate,endDate]"
                        :getCalendarContainer="(triggerNode) => triggerNode.parentNode"/>
      </div>
    </div>
    <div class="searchForm">
      <div class="item">
        <span>经销商审批状态：</span>
        <a-select
          v-model="org"
          style="width: 140px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item of statusList"
            :key="item.id"
            :value="item.id"
          >{{ item.label }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item">
        <span>小区审批状态：</span>
        <a-select
          v-model="sArea"
          style="width: 140px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item of statusList"
            :key="item.id"
            :value="item.id"
          >{{ item.label }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item">
        <span>大区审批状态：</span>
        <a-select
          v-model="lArea"
          style="width: 140px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item of statusList"
            :key="item.id"
            :value="item.id"
          >{{ item.label }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item">
        <span>业务部审批状态：</span>
        <a-select
          v-model="admin"
          style="width: 140px"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item of statusList"
            :key="item.id"
            :value="item.id"
          >{{ item.label }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >查询
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="table">
      <a-table :data-source="data"
               :columns="columns"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               :scroll="{ x: 1800 }">
        <span class="actionBox" slot="action" slot-scope="text, record">
          <a v-if="record.can_approval" @click="approval(record.id,1)">通过</a>
          <a v-if="record.can_approval" @click="approval(record.id,2)">拒绝</a>
        </span>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getApprovalApply, putApprovalAction, getApprovalList } from '@/api/offline/Details';
import SelectOrg from '@/components/select/SelectOrg';
import Common from '@/utils/Common';
import Pagination, { getPagination } from '@components/Pagination';

export default {
  name: 'offlineAppSign',
  components: { SelectOrg, Pagination },
  data() {
    return {
      statusList: [
        { id: '', label: '不限' },
        { id: '0', label: '未审批' },
        { id: '1', label: '已通过' },
        { id: '2', label: '未通过' },
      ],
      businessList: [],
      columns: [
        {
          width: 200,
          fixed: 'left',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '业务名称',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'code',
          key: 'code',
          title: '代码',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'created_at',
          key: 'created_at',
          title: '申请时间',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'small_regional_approval_status',
          key: 'small_regional_approval_status',
          title: '小区审批状态',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'small_regional_approval_time',
          key: 'small_regional_approval_time',
          title: '小区审批时间',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'regional_approval_status',
          key: 'regional_approval_status',
          title: '大区审批状态',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'regional_approval_time',
          key: 'regional_approval_time',
          title: '大区审批时间',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'org_approval_status',
          key: 'org_approval_status',
          title: '经销商审批状态',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'org_approval_time',
          key: 'org_approval_time',
          title: '经销商审批时间',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'admin_approval_status',
          key: 'admin_approval_status',
          title: '业务部审批状态',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'admin_approval_time',
          key: 'admin_approval_time',
          title: '业务部审批时间',
        },
        {
          width: 140,
          fixed: 'right',
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      readOnly: false,
      loading: false,
      pagination: getPagination(),
      trainingId: '',//选择辅导业务
      orgIds: '', //选择经销商id
      trainName: '',
      startDate: '',
      endDate: '',
      org: '',
      sArea: '',
      lArea: '',
      admin: '',
    };
  },
  created() {
    this.getApproval();
    this.getData(1, this.pagination.defaultPageSize);
  },
  destroyed() {
  },
  methods: {
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const json = {
        'org': this.org || '',//经销商审批,0:未审批,1:通过,2:未通过
        's_area': this.sArea || '',//小区审批,0:未审批,1:通过,2:未通过
        'l_area': this.lArea || '',//大区审批,0:未审批,1:通过,2:未通过
        'admin': this.admin || '',//业务部审批,0:未审批,1:通过,2:未通过
      };
      const params = {
        training_id: this.trainingId || '',
        approval_status: json,
        start_date: this.startDate,
        end_date: this.endDate,
        org_id: this.orgIds || '',
      };
      const data = await getApprovalApply({
        page: page,
        per_page: pageSize,
        ...params,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];
      const pagination = data?.meta || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    // 获取辅导业务列表
    async getApproval() {
      const data = await getApprovalList();
      if (!data || data.error_code) {
        return;
      }
      this.businessList = data?.data || [];
    },
    // 选择经销商
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      this.getData();
    },
    // 重置
    reset() {
      this.trainingId = '';
      this.startDate = '';
      this.endDate = '';
      this.orgIds = '';
      this.org = '';
      this.sArea = '';
      this.lArea = '';
      this.admin = '';
      this.getData();
    },
    // 选择申请时间
    chooseTime(date, str) {
      this.startDate = str[0];
      this.endDate = str[1];
    },
    // 审批
    async approval(id, type) {
      this.$confirm({
        content: () => <div>确认审批？</div>,
        onOk: async () => {
          const data = await putApprovalAction({
            id: id,
            type: type,
            action_type: 1,
          }).finally(() => this.loading = false);
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success(data.message);
          this.getData();
        },
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineAppSign {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .table {
    width: 100%;
    padding: 5px 15px 10px 20px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}
</style>

