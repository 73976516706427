<template>
  <div class="full-width">
    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span v-if="type === 'teacher'" class="flex-none">讲师姓名：</span>
        <span v-else-if="type === 'student'" class="flex-none">学员姓名：</span>
        <a-input v-model="filterText"
                 :placeholder="'请输入' + (
                   type === 'teacher' ? '讲师' : (
                     type === 'student' ? '学员' : ''
                   )
                 ) + '名称'"
                 :allow-clear="true"
                 style="width: 160px"/>
      </div>

      <div v-if="trainingType === 1" class="flex mr-30 mb-10">
        <span v-if="type === 'teacher'" class="flex-none">班次：</span>
        <span v-else-if="type === 'student'" class="flex-none">*班次：</span>
        <SelectClasses v-model="classesId" :list="classList"/>
      </div>
      <div v-if="trainingType === 2" class="flex mr-30 mb-10">
        <span v-if="type === 'teacher'" class="flex-none">阶段：</span>
        <span v-else-if="type === 'student'" class="flex-none">*阶段：</span>
        <a-select v-model="stage"
                  placeholder="请选择阶段"
                  style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="(item) of stageList" :key="item.id" :value="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="flex flex-wrap ph-20 mb-10"
         :class="{
      'flex-start': type === 'student' || trainingType === 2,
      'flex-end': !(type === 'student' || trainingType === 2),
    }">
      <div v-if="type === 'student'" class="flex mr-30 mb-10">
        <span class="flex-none">岗位：</span>
        <app-select-job v-model="postIds"/>
      </div>
      <div v-if="type === 'student' || trainingType === 2" class="flex mr-30 mb-10">
        <span v-if="type === 'teacher'" class="flex-none">组织机构：</span>
        <span v-else-if="type === 'student'" class="flex-none">组织机构：</span>
        <app-select-org v-model="orgIds"
                        :max="trainingType === 2 ? 1 : 0"
                        :data="trainingType === 2 ? orgList : undefined"/>
      </div>

      <div class="flex">
        <a-button type="primary" @click="onSearch()">查询</a-button>
        <a-button @click="onReset">重置</a-button>
      </div>
    </div>

    <div class="flex space-between ph-20 mb-10">
      <div class="flex flex-start flex-grow mr-30">
        <a-button v-for="(label) in labels"
                  :key="label.id"
                  :type="labelId === label.id ? 'primary' : ''"
                  class="mr-5"
                  @click="changeLabel(label.id)">
          {{ label.title }}
        </a-button>
      </div>
      <a-button class="flex-none" @click="onExport2()">导出</a-button>
    </div>

    <div class="ph-20 full-width">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               ref="tableRef"
               bordered
               rowKey="rowId"
               :scroll="{ x: '100%' }"
               class="small-cell-table table">
        <template slot="idcard" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="idcard"/>
          </div>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="mobile"/>
          </div>
        </template>

        <span slot="files" slot-scope="text">
          <a v-for="file in text"
             :key="file.id"
             :href="file.url"
             target="_blank"> {{ file.type_str }} </a>
        </span>
        <span slot="status" slot-scope="text">{{ text === 0 ? '未提交' : '已提交' }}</span>
        <span slot="action" slot-scope="text, record">
          <a @click="showWriteCommentModal(record)">写评价</a>
        </span>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <WriteCommentModal ref="writeCommentModalRef"
                       @success="getData(
                         pagination.current,
                         pagination.pageSize
                       )"/>

    <DownloadReasonModal ref="downloadReasonModalRef" @saved="onExport"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import table from '@/mixins/table';

import {
  getTrainingOrgTree,
} from '../api/api';
import {
  getMaterialLabel,
  getStudentMaterialList,
  getStudentMaterialListDownload,
  getTeacherMaterialList,
  getTeacherMaterialListDownload,
} from '../api/submitMaterial';
import {
  getPagination,
  updatePagination,
} from '@/components/table/Pagination';
import SelectClasses from '@/components/select/SelectClasses';

import WriteCommentModal from './WriteCommentModal';

import CiphertextViewer from '@/components/viewer/CiphertextViewer.vue'
import DownloadReasonModal from '@/views/download/record/components/DownloadReasonModal.vue'

const columns = [
  {
    width: '120px',
    fixed: 'left',
    align: 'center',
    dataIndex: 'truename',
    key: 'truename',
    title: '姓名',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
    title: '大区',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'small_regional_org_name',
    key: 'small_regional_org_name',
    title: '小区',
  },
  {
    width: '280px',
    align: 'center',
    dataIndex: 'orgname',
    key: 'orgname',
    title: '经销商',
  },
  {
    width: '190px',
    align: 'center',
    dataIndex: 'idcard',
    key: 'idcard',
    title: '身份证号',
    scopedSlots: { customRender: 'idcard' }
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    title: '电话',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'postname',
    key: 'postname',
    title: '岗位',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'classes_name',
    key: 'classes_name',
    title: '对应班次',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'status',
    key: 'status',
    title: '当前状态',
    scopedSlots: { customRender: 'status' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'files',
    key: 'files',
    title: '成果物',
    scopedSlots: { customRender: 'files' },
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'sub_date',
    key: 'sub_date',
    title: '提交时间',
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'overdue_days',
    key: 'overdue_days',
    title: '逾期天数',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'summary',
    key: 'summary',
    title: '评价',
    autoExpand: true,
  },
  {
    width: '100px',
    fixed: 'right',
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'SubmitPage',
  mixins: [table],
  components: {
    CiphertextViewer,
    DownloadReasonModal,
    SelectClasses,
    WriteCommentModal,
  },
  props: {
    type: { type: String, default: '' },
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
    columns() {
      const excludes = ['postname', 'idcard'];
      let columns = [...this.originColumns];

      if (this.type === 'teacher') {
        if (this.trainingType === 1) {
          excludes.push('regional_org_name', 'small_regional_org_name', 'orgname');
        }
        columns = columns.filter((item) => !excludes.includes(item.key));
      }

      return columns;
    },
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
        if (type) {
          this.onSearch();
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      trainingId: this.$route.query.id || '',

      classesId: '',
      stage: '',
      postIds: '',
      orgIds: '',
      filterText: '',

      classList: [],
      stageList: [],
      orgList: undefined,

      data: [],
      originColumns: columns,

      labels: [], // 标签列表
      labelId: '', // 选中标签 id
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.trainingId);
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;

    this.originColumns = columns.map((column) => {
      const item = { ...column };
      if (column.key === 'classes_name') {
        item.title = this.trainingType === 1 ? '对应班次' : '对应阶段';
      }

      return item;
    });

    if (this.type === 'teacher' && this.trainingType) {
      this.onSearch();
    }
  },
  methods: {
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    onSearch() {
      this.getLabel();
    },
    onReset() {
      this.classesId = '';
      this.stage = '';
      this.postIds = '';
      this.orgIds = '';
      this.filterText = '';

      this.labels = [];
      this.labelId = '';
      this.data = [];
      this.pagination = getPagination();

      this.getLabel();
    },
    onExport2() {
      this.$refs.downloadReasonModalRef.show()
    },
    async onExport(evt) {
      if (this.downloading) {
        return;
      }
      this.downloading = true;

      const params = {
        page: this.pagination.current,
        per_page: this.pagination.pageSize,

        training_id: this.trainingId,
        training_type: this.trainingType,

        truename_or_username_or_idcard: this.filterText,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        classes_id_str: this.classesId,
        stage: this.trainingType === 2 ? this.stage : '',

        material_id: this.labelId,

        is_download: 1,
      };

      Object.keys(evt || {}).forEach((key) => params[key] = evt[key])

      const materialMap = {
        student: getStudentMaterialListDownload,
        teacher: getTeacherMaterialListDownload,
      };
      const data = await materialMap[this.type](params).then(() => {
        this.$refs.downloadReasonModalRef.close()
        this.$message.success(evt.down_type === 1 ? '申请成功' : '下载成功')
      }).catch(() => {
        this.$refs.downloadReasonModalRef.stopLoading()
      }).finally(() => {
        this.downloading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data.message || '导出失败');
        return;
      }
      this.$message.success(data.message || '导出成功');
    },

    changeLabel(id) {
      this.labelId = id || '';

      this.getData();
    },

    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.trainingId,
      });
      this.orgList = data?.data || undefined;
    },

    // 提交材料标签
    async getLabel() {
      if (this.trainingType === 1) {
        if (!this.classesId && this.type !== 'teacher') {
          this.$message.info('请选择班次');
          return;
        }
      } else {
        if (!this.stage && this.type !== 'teacher') {
          this.$message.info('请选择阶段');
          return;
        }
        if (!this.orgIds && this.type !== 'teacher') {
          this.$message.info('请选择组织机构');
          return;
        }
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const typeMap = {
        teacher: 1,
        student: 2,
      };
      const params = {
        training_id: this.trainingId,
        training_type: this.trainingType,

        classes_id_str: this.classesId,
        stage: this.stage,
        post_id_str: this.postIds,
        sub_role_type: typeMap[this.type] || '',
      };

      const data = await getMaterialLabel(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取标签失败');
        return;
      }
      this.labels = data?.data || [];
      this.labelId = this.labels[0]?.id || '';
      if (this.labelId) {
        this.getData();
      } else {
        this.data = [];
        this.pagination = getPagination();
      }
    },

    async getData(page, pageSize) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        page: page,
        per_page: pageSize,

        training_id: this.trainingId,
        training_type: this.trainingType,

        truename_or_username_or_idcard: this.filterText,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        classes_id_str: this.classesId,
        stage: this.trainingType === 2 ? this.stage : '',

        material_id: this.labelId,
      };

      const materialMap = {
        student: getStudentMaterialList,
        teacher: getTeacherMaterialList,
      };
      const data = await materialMap[this.type](params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.data = (data?.data || []).map((item, index) => ({
        ...item,
        rowId: item.rowId || `row_${page}_${index}`,
      }));
      this.pagination = updatePagination(this.pagination, data?.meta?.pagination || {});
    },

    showWriteCommentModal(record) {
      if (!record.user_material_id) {
        this.$message.info('未提交材料,无法评价');
        return;
      }

      if (this.$refs.writeCommentModalRef?.show) {
        this.$refs.writeCommentModalRef.show({
          materialId: record.user_material_id,
          summary: record.summary,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
