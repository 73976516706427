<template>
  <div class="left-bar-item"
       @click="activate">
    <!--:class="activeId === data.rowId && !data.isCheckbox ? 'active' : ''"-->
    <div class="left-bar-item-layer">
      <div v-if="data.isCheckbox" class="checkbox" @click.stop="">
        <a-checkbox :checked="allOrgShown" @change="allOrgShownChange">展示全部经销商</a-checkbox>
      </div>

      <div v-else
           class="left-bar-item-wrapper"
           :class="data.parent === 1 ? 'two' : data.parent === 2 ? 'three' : ''">

        <template v-if="!data.isRowOverflowNext && !data.isCollapsedNext">
          <div v-show="data.parent < 2 && data.data && data.data.length"
               class="jt-wrapper"
               @click.stop="expand">
            <div class="jt" :class="expanded ? '' : 'jt180deg'"/>
          </div>

          <img v-if="data.parent < 2" src="../assets/img/icon1.png" class="img" alt=""/>
          <img v-else :src="activeId === data.rowId ? icon2 : icon1" class="img" alt=""/>

          <div class="name">
            <a-tooltip :title="data.name" placement="top" class="name-inner">{{ data.name }}</a-tooltip>
          </div>

          <span class="count">{{ data.count || data.applies_count || 0 }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftBarItem',
  props: {
    activeId: { type: [Number, String], default: undefined },
    data: { type: Object, default: () => ({}) },
    allOrgShown: { type: Boolean, default: false },
  },
  data() {
    return {
      expanded: this.data.expanded,
      icon1: require('../assets/img/icon2-1.png'),
      icon2: require('../assets/img/icon2-2.png'),
    };
  },
  methods: {
    activate() {
      this.$emit('activate', {
        rowId: this.data.rowId,
        checked: !this.data.checked,
      });
    },
    expand() {
      this.expanded = !this.expanded;
      this.$emit('expand', {
        rowId: this.data.rowId,
        expanded: this.expanded,
      });
    },
    allOrgShownChange(evt) {
      this.$emit('allOrgShownChange', evt?.target?.checked || false);
    },
  },
};
</script>

<style scoped lang="scss">
.left-bar-item {
  position: relative;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  cursor: pointer;

  .left-bar-item-layer {
    position: relative;
    z-index: 9;
    height: 100%;
    padding-left: 17px;
    padding-right: 15px;
  }

  .name {
    position: relative;
    z-index: 1;
    flex: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .name-inner {
      //display: inline-block;
    }
  }

  .count {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-left: auto;
    background-color: #E1E5F2;
    color: #334FA0;
  }

  &.active {
    font-size: 14px;
    font-weight: 600;
    color: #334FA0;
    background: linear-gradient(90deg, rgba(83, 129, 209, 0) 0%, rgba(51, 79, 160, 0.12) 100%);

    .count {
      background-color: #334FA0;
      color: #fff;
    }

    &:after {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      border-right: 4px solid #334FA0;
    }
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  height: 100%;
}

.left-bar-item-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;

  img {
    width: 14px;
    margin: 0 10px;
  }

  .jt, .img, .count {
    flex: none;
  }
}

.jt-wrapper {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 40px;
}

.jt {
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top: 8px solid #333;
}

.jt180deg {
  transform: rotate(-90deg);
}

.two {
  padding-left: 20px;
}

.three {
  padding-left: 40px;
}

.collapse-next {
  height: 40%;
  width: 60%;
  margin-top: -10px;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;

  &.two {
    margin-left: 20px;
  }

  &.three {
    margin-left: 40px;
  }
}
</style>
