<template>
  <div class="pd20">
    <div class="flex-box">
      <div class="flex-box pb20">
        <span class="lh30 pr10" style="width: 120px">类型：</span>
        <a-select placeholder="请选择" class="w200" v-model="type">
          <a-select-option
            v-for="item in TYPE_SELECT"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span class="lh30 plr10" style="width: 190px">提交角色：</span>
        <a-select placeholder="请选择" class="w200" v-model="role">
          <a-select-option
            v-for="item in ROLE_SELECT"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span class="lh30 plr10" style="width: 120px">名称：</span>
        <a-input placeholder="请输入" class="w200 mr10" v-model="title"/>
        <searchAndReset
          @handleSearch="handleSearch"
          @getData="getData"
        ></searchAndReset>
      </div>
      <a-button @click="openLayer()" type="primary"> 添加回传项</a-button>
    </div>
    <a-table
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      :loading="loading"
    >
      <template slot="eg_file_url" slot-scope="text, record">
        <a-button type="link" @click="download(record)">
          {{ record.eg_file_resource && record.eg_file_resource.download_url ? '预览' : '--' }}
        </a-button>
      </template>
      <template slot="action" slot-scope="text, record">
        <a @click="openLayer(record)">编辑</a>
        <a-divider type="vertical"/>
        <a @click="deleteData(record)">删除</a>
      </template>
      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"
        ></Pagination>
      </template>
    </a-table>
    <div class="line"></div>
    <saveButtons
      ref="saveButtons"
      :show-save="false"
      next-text="下一步"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <addExamLayer
      ref="layer"
      :typeSelectList="TYPE_SELECT"
      @success="getData"
    ></addExamLayer>
  </div>
</template>

<script>
import { ConfigMixin } from './mixins/configMixins';
import { getMaterialsList, delMaterial } from '@/api/config';
import { Divider } from 'ant-design-vue';
import { ROLE_SELECT, TYPE_SELECT } from './constants';
import Pagination from '@components/Pagination';
import Common from '@/utils/Common';
import { mapState } from 'vuex';

const columns = [
  {
    title: '类型',
    dataIndex: 'category',
    // scopedSlots: { customRender: 'category' },
    customRender: (text) => {
      switch (text) {
        case 'cont':
          return '文件';
        case 'img':
          return '图片';
        case 'video':
          return '视频';
        default:
          return text;
      }
    },
  },
  {
    title: '名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '提交角色',
    dataIndex: 'sub_role_type',
    scopedSlots: { customRender: 'sub_role_type' },
    customRender: (text) => {
      return ROLE_SELECT.find((item) => item.value === text).label;
    },
  },
  {
    title: '示例文件',
    dataIndex: 'eg_file_url',
    scopedSlots: { customRender: 'eg_file_url' },
  },
  {
    title: '数量要求',
    dataIndex: 'num',
    scopedSlots: { customRender: 'num' },
    customRender: (text) => {
      return text + '个';
    },
  },
  {
    title: '描述',
    dataIndex: 'desc',
    scopedSlots: { customRender: 'desc' },
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    title: '最晚提交时间',
    dataIndex: 'sub_end_date_fmt',
    scopedSlots: { customRender: 'sub_end_date_fmt' },
    customRender: (text) => {
      return text ? text : '--';
    },
  },
  {
    hidden: true,
    title: '辅导阶段',
    dataIndex: 'stage',
    customRender: (text) => `第${text}阶段`,
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  name: 'materialConfiguration',
  mixins: [ConfigMixin],
  components: {
    saveButtons: () => import('./components/saveButtons'),
    addExamLayer: () => import('./components/addMaterialLayer'),
    searchAndReset: () => import('./components/searchAndReset'),
    'a-divider': Divider,
    Pagination,
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
      isAppoint: state => state.Offline.isAppoint,
    }),
    columns() {
      return columns.filter((i) => this.trainingType === 2 || !i.hidden);
    },
  },
  data() {
    return {
      ROLE_SELECT,
      TYPE_SELECT,
      data: [],
      form: {},
      loading: false,
      type: undefined,
      role: undefined,
      title: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onPageChange(page, pageSize) {
      // this.getData(page, pageSize);
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.handleSearch();
    },
    onSizeChange(current, size) {
      // this.getData(1, size);
      this.pagination.current = 1;
      this.pagination.pageSize = size;
      this.handleSearch();
    },
    getData() {
      this.type = undefined;
      this.title = '';
      this.role = undefined;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.handleSearch();
    },
    handleSearch() {
      let type = this.type ? this.type : '';
      let role = this.role ? this.role : '';
      let params = `?training_id=${this.$route.query.id}&page=${this.pagination.current}&per_page=${this.pagination.pageSize}&title=${this.title}&type=${type}&role=${role}`;
      this.handleTableChange(params);
    },
    async handleTableChange(params) {
      this.loading = true;
      const data = await getMaterialsList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.data = data.data;
        this.pagination.total = data.meta.total;
      }
    },
    download(record) {
      if (!record.eg_file_resource.download_url) {
        return this.$message.error('暂无实例文件');
      }
      const url = record?.eg_file_resource?.download_url || '';
      const title = record?.eg_file_resource?.down_title || '';
      Common.downloadFile(url.indexOf('?') > -1 ? `${url}&title=${title}` : `${url}?title=${title}`, title);
    },
    openLayer(data) {
      this.$nextTick(() => {
        this.$refs.layer.openMe(data);
      });
    },
    handleSubmit() {
      this.goNextStep('trainingMaterialsUpload');
    },
    async delApiData(record) {
      this.loading = true;
      const data = await delMaterial({ id: record.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.w50 {
  width: 100px;
}

.mr10 {
  margin-right: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.lh30 {
  line-height: 30px;
}

.w200 {
  width: 200px;
}

.pr10 {
  padding-right: 10px;
}

.plr10 {
  padding: 0 10px;
}

.line {
  height: 32px;
}
</style>
