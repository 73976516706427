import request from '@/utils/Request';

// 获取辅导类型，参与辅导的经销商
export function getTrainingOrgTree(params) {
  return request({
    url: '/offline/admin/training/tutoring/joinorgs',
    method: 'get',
    params,
  });
}
