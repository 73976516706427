<template>
  <div class="main">
    <a-form-model :model="formInfo" v-bind="layout">
      <a-form-model-item has-feedback label="培训方式：">
        <a-input v-model="formInfo.type" autocomplete="off" placeholder="请输入培训方式" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="培训日期：">
        <a-date-picker v-model="formInfo.date" @change="handlerDate" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="具体时间：">
        <a-time-picker
          v-model="time_start"
          format="HH:mm"
          @change="(value, valueStr) => { changeStart(value, valueStr) }"
        />-
        <a-time-picker
          v-model="time_end"
          format="HH:mm"
          @change="(value, valueStr) => { changeEnd(value, valueStr) }"
        />
      </a-form-model-item>
      <a-form-model-item has-feedback label="培训人：">
        <a-input v-model.number="formInfo.trainer" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="老师电话：">
        <a-input v-model.number="formInfo.mobile" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="组织部门：">
        <a-input v-model.number="formInfo.org_department" placeholder="请输入" />
      </a-form-model-item>
      <!-- <a-form-model-item has-feedback label="学分：" prop="age">
        <a-input v-model.number="formInfo.age" placeholder="请输入"/>
      </a-form-model-item>-->
      <a-form-model-item has-feedback label="备注：">
        <a-textarea v-model.number="formInfo.remark" placeholder="请输入" />
      </a-form-model-item>
      <!-- <a-form-model-item has-feedback label="是否测试：">
        <a-select label-in-value placeholder="请选择" @change="handleChange">
          <a-select-option value="1">是</a-select-option>
          <a-select-option value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>-->
    </a-form-model>
    <SaveButtons class="mt20" ref="saveButtons" @handleSubmit="submitForm" :showNext="false" />
  </div>
</template>

<script>
import { getDesignplanDetail, getDesignplanSave } from './api/api'
import moment from 'moment'
import Common from '@/utils/Common';
import SaveButtons from './components/saveButtons'

export default {
  name: "trainPlanConfig",
  components: {
    SaveButtons,
  },
  data() {
    return {
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      formInfo: {},
      // TODO: refactor,
      time_start: "",
      time_end: "",
      start: '',
      end: '',
      loading: false,
      isDisabled: false
    }
  },
  mounted() {
    this.getDesignplanDetail();
  },
  methods: {
    async getDesignplanDetail() {
      const { data } = await getDesignplanDetail({ training_id: this.$route.query.id });
      this.formInfo = data;
      this.handlerTimeRange(data)
    },
    handlerTimeRange(data) {
      // TODO: refactor,
      if (data.time_range) {
        const time = data.time_range.split('-');
        this.time_start = moment(time[0], 'HH:mm')
        this.time_end = moment(time[1], 'HH:mm')
        this.start = time[0]
        this.end = time[0]
      }
      console.log('*********', this.time_start, this.time_end);
    },
    handlerDate(e, date) {
      this.formInfo.date = date
    },
    changeStart(e, date) {
      this.time_start = e
      this.start = date
    },
    changeEnd(e, date) {
      this.time_end = e
      this.end = date
    },
    submitForm: Common.debounce(function () {
      try {
        if (this.isDisabled) {
          return
        }
        this.loading = true
        this.isDisabled = true
        this.formInfo.time_range = this.start && this.end ? `${this.start}-${this.end}` : ''
        const params = {
          training_id: this.$route.query.id,
          ...this.formInfo,
        }
        getDesignplanSave(params)
        this.isDisabled = false
        this.$message.success('保存成功');
       setTimeout(() => {
          this.getDesignplanDetail();
       }, 500)
        this.loading = false
      } catch (error) {
        this.isDisabled = true
        this.$message.success(error.message);
      }
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 700px;
  margin: 0 auto;
}
</style>
