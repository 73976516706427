<template>
  <SubmitPage type="teacher"/>
</template>

<script>
import SubmitPage from './components/SubmitPage';

export default {
  name: 'SubmitTeacher',
  components: {
    SubmitPage,
  },
};
</script>

<style scoped lang="scss">

</style>
