<template>
  <div class="plan-export">
    <div class="search">
      <a-form-model :model="searchQuery" layout="inline">
        <a-form-model-item label="选择业务">
          <a-select
            v-model="training_ids"
            mode="multiple"
            placeholder="请选择业务"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @change="handlerSelectBu"
            @search="handleSearch"
            style="width: 346px;"
          >
            <a-select-option v-for="(item, index) in businessList" :key="index">{{ item.title }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="培训日期">
          <a-range-picker
            v-model="rangeData"
            @change="handlerDate"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="getTrainingPlanShow">查询</a-button>
          <a-button style="margin-left: 10px" @click="handlerReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="export">
      <div>共找到{{ dataSource.length }}个培训</div>
      <a-button @click="handlerExport" type="primary">导出查询结果</a-button>
    </div>
    <div class="table">
      <a-table
        :data-source="dataSource"
        :columns="columns"
        :pagination="false"
        :loading="loading"
        bordered
        row-key="id"
      ></a-table>
    </div>
  </div>
</template>

<script>
import { getTrainingPlan, getTrainingPlanShow, trainingPlanExport } from './config/api/api'
import dayjs from 'dayjs'

const columns = [
  {
    align: 'center',
    dataIndex: 'training_title',
    key: 'training_title',
    title: '业务名称',
  },
  {
    align: 'center',
    dataIndex: 'config_time',
    key: 'config_time',
    scopedSlots: { customRender: 'contain_num' },
    title: '配置时间',
    customRender: (text) => {
      return dayjs(text * 1000).format('YYYY-MM-DD')
    },
  },
  {
    align: 'center',
    dataIndex: 'date',
    key: 'date',
    title: '培训日期',
  },
  {
    align: 'center',
    dataIndex: 'time_range',
    key: 'time_range',
    title: '具体时间',
  },
  {
    align: 'center',
    dataIndex: 'create_user',
    key: 'create_user',
    title: '配置人',
  },
]
let timeout;
function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  function fake() {
    getTrainingPlan({ title: value }).then(res => {
      callback(res.data);
    });
  }
  timeout = setTimeout(fake, 300);
}
export default {
  data() {
    return {
      trainingPlanExport,
      columns,
      dataSource: [],
      loading: false,
      searchQuery: {},
      businessList: [],
      rangeData: [null, null],
      training_ids: [],
    }
  },
  mounted() {
    fetch('', data => (this.businessList = data));
  },
  methods: {
    async getTrainingPlanShow() {
      this.dataInit()
      if (!this.searchQuery.training_ids && !this.searchQuery.end_date && !this.searchQuery.start_date) {
        this.$message.info({ content: '请填写查询条件' });
        return
      }
      const { data } = await getTrainingPlanShow({ ...this.searchQuery, page: 1, per_page: 15 })
      this.dataSource = data

    },
    handlerExport() {
      this.dataInit()
      trainingPlanExport({ ...this.searchQuery })
    },
    dataInit() {
      const arr = []
      if (this.training_ids.length) {
        this.training_ids.map((item) => {
          arr.push(this.businessList[item].id)
        })
      }
      this.searchQuery.training_ids = arr.join(',')
    },
    handlerDate(e, date) {
      this.searchQuery.start_date = date[0];
      this.searchQuery.end_date = date[1];
      console.log(this.searchQuery);
    },
    handlerSelectBu(value) {
      console.log('value', value);
      this.training_ids = value
      fetch(value, data => (this.data = data));
    },
    handleSearch(value) {
      fetch(value, data => (this.businessList = data));
      console.log('handleSearch', value);
    },
    handlerReset() {
      this.searchQuery = {}
      this.rangeData = null
      this.training_ids = []
      this.dataSource = []
      fetch('', data => (this.businessList = data));
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 15px 10px 20px;
}
.export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 10px 20px;
}
.table {
  width: 100%;
  padding: 5px 15px 10px 20px;
}
</style>
