<template>
  <div class="workDetails">
    <!-- 标题卡 -->
    <div class="title">
      <!-- 返回 -->
      <a-button class="backBtn" @click="returnFun">返回</a-button>
      <div class="name">{{ data.homework_title }}</div>
      <div class="info">
        <span>提交人：{{ data.user_name }}</span>
        <span>提交日期：{{ data.up_date }}</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="workTitle">
          <p>{{ workIndex + 1 }}.</p>
          <p>
            <span v-if="workInfo.category === 'cont'">问答题 </span>
            <span v-if="workInfo.category === 'img'">图片题 </span>
            <span v-if="workInfo.category === 'file'">文件题 </span>
            <span>{{ workInfo.title }}</span>
          </p>
        </div>
        <div class="sub">学员作答：</div>
        <div class="con" v-if="workInfo.category === 'cont'">{{ workInfo.answer }}</div>
        <div class="con" v-if="workInfo.category === 'img'">
          <img :src="img.url" :alt="img.down_title" v-for="img in workInfo.answer" :key="img.id"
               @click="showBigImg(img.url)">
        </div>
        <div class="con" v-if="workInfo.category === 'file'">
          <div class="file" v-for="file in workInfo.answer" :key="file.id" :alt="file.down_title">
            <span>{{ file.down_title }}</span>
            <a-icon class="icon" type="download" @click="downloadFile(file.download_url)"/>
          </div>
        </div>
        <div class="prevNext">
          <a-button type="primary" @click="prevWork">上一题</a-button>
          <a-button type="primary" @click="nextWork">下一题</a-button>
        </div>
      </div>
      <div class="right">
        <div class="workNumber">
          <a-button
            class="num"
            v-for="(item,index) in data.options" :key="index"
            :type="(workIndex == index?'primary':'default')"
            @click="showWork(index)"
          >{{ index + 1 }}
          </a-button>
        </div>
      </div>
    </div>
    <a-modal :width="888" v-model="visible" ok-text="确定" cancel-text="关闭" @cancel="visible = false"
             @ok="visible = false">
      <div class="carousel">
        <img :src="bigUrl" alt="">
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getHomeworkdDetail } from '@/api/offline/Details';
import Common from '@/utils/Common';

export default {
  name: 'workDetails',
  components: {},
  data() {
    return {
      id: this.$route.query.userHomeworkId,
      titles: [{ title: '培训配置' }, { title: '辅导报名审批' }],
      visible: false,//图片放大显示
      data: {},
      workInfo: {},
      workIndex: 0,
      bigUrl: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = {
        user_homework_id: this.id,
      };
      const data = await getHomeworkdDetail(params);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];
      this.workInfo = this.data.options[this.workIndex];
    },
    returnFun() {
      this.$router.back();
    },
    // 切换所选题目
    showWork(index) {
      this.workIndex = index;
      this.workInfo = this.data.options[index];
    },
    // 上一题
    prevWork() {
      if (this.workIndex === 0) {return;}
      this.workIndex--;
      this.showWork(this.workIndex);
    },
    // 下一题
    nextWork() {
      if (this.workIndex === this.data.options.length - 1) {return;}
      this.workIndex++;
      this.showWork(this.workIndex);
    },
    // 放大查看
    showBigImg(url) {
      this.bigUrl = url;
      this.visible = true;
    },
    // 下载图片
    downloadImg() {
      Common.downloadFile(this.bigUrl);
    },
    // 下载文件
    downloadFile(url) {
      Common.downloadFile(url);
    },
  },
};
</script>

<style scoped lang="less">
.workDetails {
  width: 1200px;
  margin: 0 auto;

  .title {
    width: 1200px;
    padding: 20px 30px;
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      padding: 0 20px;
    }

    .info {
      display: flex;

      span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        margin-right: 10px;
      }
    }
  }

  .content {
    display: flex;
    margin-top: 10px;

    .left {
      width: 70%;
      flex-shrink: 0;
      padding: 0 10px 0;

      .workTitle {
        font-size: 16px;
        display: flex;

        > p:first-child {
          width: 20px;
        }
      }

      .sub {
        font-size: 16px;
        padding-left: 20px;
      }

      .con {
        min-height: 300px;
        padding: 20px;

        img {
          width: 200px;
          margin: 10px;
        }

        .file {
          padding: 10px;
          background: #cdcdcd;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;
          }

          .icon {
            cursor: pointer;
            font-size: 20px;
          }
        }
      }

      .prevNext {
        width: 300px;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;
      }
    }

    .right {
      flex: 1;
      position: relative;
      padding-left: 20px;
      padding-right: 00px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background: #cdcdcd;
      }

      .workNumber {
        display: flex;
        flex-wrap: wrap;

        .num {
          width: 16%;
          margin: 0;
          margin-right: 5%;
          margin-bottom: 10px;

          &:nth-child(5n) {
            margin-right: 0;
          }
        }
      }

      .trainer-info-r-count {
        margin: 0 auto;
        text-align: center;
        margin-top: 50px;
      }
    }
  }
}

.carousel {
  img {
    max-width: 777px;
  }
}
</style>
