<template>
  <a-upload name="file"
            action="/public/conf/upload"
            :headers="{ 'X-Auth-Token': $store.state.User.token || '' }"
            v-bind="$attrs"
            v-on="$listeners"
            :file-list="fileList"
            :remove="removeFile"
            @change="uploadChange">
    <slot></slot>
  </a-upload>
</template>

<script>
export default {
  name: 'Uploader',
  model: {
    prop: 'value',
    event: 'idsChange',
  },
  props: {
    value: { type: [String, Number], default: '' },
    // [{id: xxx, url: 'xxxx/xx.xxx'}] id为资源id（file_resource_id）url为资源地址 down_title为文件显示名称
    customFileList: { type: Array, default: () => [] },
    count: { type: Number, default: () => 0 },
  },
  watch: {
    customFileList: {
      immediate: true,
      handler(val) {
        if (!val?.length) {
          this.fileList = [];
          return;
        }

        const fileList = [];
        val.forEach((i) => {
          fileList.push({
            uid: this.globalId--,
            name: i.down_title,
            status: 'done',
            url: i.url,
            file_resource_id: i.id,
          });
        });

        this.fileList = fileList;
      },
    },
    fileList: {
      immediate: true,
      handler(val) {
        if (this.count === 1) {
          if (this.fileList.length > 1) {
            this.fileList = [this.fileList[1]];
          }
          this.$emit('file-list-change', [
            ...(val || []).map((i) => ({ ...i })),
          ]);
        } else {
          this.$emit('file-list-change', [
            ...(val || []).map((i) => ({ ...i })),
          ]);
        }
      },
    },
  },
  data() {
    return {
      globalId: -1,
      fileList: [],
      uploading: false,
    };
  },
  methods: {
    change() {
      const ids = this.fileList.map((i) => i.file_resource_id).join(',');
      this.$emit('idsChange', ids);
      this.$emit('uploadChange', [...this.fileList.map((i) => ({ ...i }))]);
      this.$emit('uploadLoainng', false);
    },

    uploadChange(info) {
      this.fileList = [...(info.fileList || [])];
      if (info.file.status === 'uploading') {
        this.uploading = true;
      }
      if (info.file.status === 'done') {
        this.uploading = false;
        this.$message.success('文件上传成功');

        this.fileList.forEach((i) => {
          i.file_resource_id =
            i.file_resource_id || i.response?.data?.file_resource_id;
        });
        this.change();
      }
      if (info.file.status === 'removed') {
        this.uploading = false;
      }
      if (info.file.status === 'error') {
        this.uploading = false;
      }
      this.$emit('uploadLoainng', this.uploading);
    },

    removeFile(file) {
      this.fileList = [...this.fileList.filter((i) => i.uid !== file.uid)];
      this.change();
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
