<template>
  <div class="pd20">
    <a-button @click="openLayer()" class="mb10" type="primary">
      添加调研
    </a-button>
    <a-table
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      @change="handleTableChange"
      :loading="loading"
      bordered
    >
      <template slot="action" slot-scope="text, record">
        <a @click="openLayer(record)">编辑</a>
        <a-divider type="vertical"/>
        <a @click="deleteData(record)">删除</a>
      </template>
      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"
        ></Pagination>
      </template>
    </a-table>
    <div class="line"></div>
    <SaveButtons
      ref="saveButtons"
      :showSave="false"
      next-text="下一步"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    />
    <AddSurveyLayer
      ref="layer"
      @success="handleTableChange"
    />
  </div>
</template>

<script>
import { ConfigMixin } from './mixins/configMixins';
import { getSurveyList, deleteSurveyList } from '@/api/config';
import { Divider } from 'ant-design-vue';
import SaveButtons from './components/saveButtons';
import AddSurveyLayer from './components/addSurveyLayer';
import { ALERT_SELECT } from './constants';
import Pagination from '@components/Pagination';
import { mapState } from 'vuex';

const columns = [
  {
    title: '调研名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '弹出位置',
    dataIndex: 'alert',
    scopedSlots: { customRender: 'alert' },
    customRender: (text) => {
      let list = ALERT_SELECT;
      return list.find(item => item.value === text + '').label;
    },
  },
  {
    title: '问卷地址',
    dataIndex: 'research_url',
    scopedSlots: { customRender: 'research_url' },
  },
  {
    hidden: true,
    title: '辅导阶段',
    dataIndex: 'stage',
    customRender: (text) => `第${text}阶段`,
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'surveyConfiguration',
  mixins: [ConfigMixin],
  components: {
    'a-divider': Divider,
    Pagination,
    SaveButtons,
    AddSurveyLayer,
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
      isAppoint: state => state.Offline.isAppoint,
    }),
    columns() {
      return columns.filter((i) => this.trainingType === 2 || !i.hidden);
    },
  },
  data() {
    return {
      data: [],
      ALERT_SELECT,
    };
  },
  created() {
    this.handleTableChange();
  },
  methods: {
    handleTableChange() {
      let params = {
        training_id: this.$route.query.id,
        page: this.pagination.current,
        per_page: this.pagination.pageSize,
      };
      this.getApiData(params);
    },
    async getApiData(params) {
      this.loading = true;
      const data = await getSurveyList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.data = data.data;
        this.pagination.total = data.meta.total;
      }
    },
    openLayer(record) {
      this.$nextTick(() => {
        this.$refs.layer.openMe(record);
      });
    },
    handleSubmit() {
      this.goNextStep('materialConfiguration');
    },
    async delApiData(record) {
      this.loading = true;
      const data = await deleteSurveyList({ id: record.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        this.handleTableChange();
      }
    },
  },
};
</script>

<style scoped>
.pd20 {
  padding: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.line {
  height: 32px;
}
</style>
