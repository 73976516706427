<template>
  <div class="offlineAction">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item">
        <span>*阶段：</span>
        <a-select v-model="stageId" placeholder="请选择阶段" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of stageList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div class="row row-left">
        <div class="col col-left item">
          <span class="title">岗位：</span>
          <SelectJob
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>

      <div class="row row-left">
        <div class="col col-left">
          <span class="title">*组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            v-model="orgIds"
            :max="trainingType === 2 ? 1 : 0"
            :data="trainingType === 2 ? orgList : undefined"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="actionInfo" v-if="actionInfo.created_at">
      <span>会签日期：{{ actionInfo.created_at }}</span>
      <span>辅导教练：{{ actionInfo.fudao_user }}</span>
      <span>制定人：{{ actionInfo.zhiding_user }}</span>
      <span>经销商总经理：{{ actionInfo.regional_org_user }}</span>
      <span>区域经理：{{ actionInfo.small_regional_org_user }}</span>
    </div>
    <div class="actionDom">
      <div class="tabBox">
        <a-button :type="planId === item.id?'primary':''" v-for="(item,index) in planTab" :key="index"
                  @click="changeTab(item.id)">
          {{ item.title }}
        </a-button>
      </div>
      <a-button @click="download()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="parsedPlanColumns"
               :data-source="planData"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               :scroll="{ x: planData[0] && planData[0].diy_content ? '100%' : null }"/>
    </div>
    <div class="table">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered row-key="username"
               :scroll="{ x: 2200 }">
        <span slot="files" slot-scope="text"><a :href="file.url" target="_blank" v-for="file in text"
                                                :key="file.id"> {{ file.type_str }} </a></span>
        <!-- <span class="actionBox" slot="action" slot-scope="text, record">
          <a @click="writeEvaluation(record.user_plan_id,record.area_summary)">写评价</a>
        </span> -->
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
    <a-modal title="请填写评价"
             :visible="visible"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-textarea
        v-model="writeText"
        placeholder="请填写评价内容"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import Common from '@/utils/Common';
import { getPlanList, getPlanLabel, postPlan } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';
import { getTrainingOrgTree } from './api/api';

export default {
  name: 'offlineAction',
  components: { SelectOrg, SelectJob, Pagination },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
    parsedPlanColumns() {
      return [
        ...(this.planColumns || []).map((i) => ({
          ...i,
          width: '180px',
        })),
        ...(this.planData?.[0]?.diy_content?.length ? (
          this.planData?.[0]?.diy_content?.map((i, idx) => ({
            width: '180px',
            align: 'center',
            dataIndex: `diy_${idx}`,
            key: `diy_${idx}`,
            title: i.title,
          }))
        ) : []),
      ];
    },
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
      }
    }
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 2,//培训Type
      planTab: [],//计划tab
      planData: [],//计划信息
      stageList: [],//阶段选项
      classList: [],//班次选项
      planColumns: [
        {
          width: '',
          align: 'center',
          dataIndex: 'tutor_model',
          key: 'tutor_model',
          title: '辅导模块',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'improve_project',
          key: 'improve_project',
          title: '改进项目',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'improve_detail',
          key: 'improve_detail',
          title: '改进内容',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'improve_target',
          key: 'improve_target',
          title: '改进目标',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'action_detail',
          key: 'action_detail',
          title: '行动步骤',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'principal',
          key: 'principal',
          title: '负责人',
        },
      ],
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: 160,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '阶段',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'sub_status',
          key: 'sub_status',
          title: '当前状态',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'files',
          key: 'files',
          title: '成果物',
          scopedSlots: { customRender: 'files' },
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'sub_date',
          key: 'sub_date',
          title: '提交时间',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'overdue_days',
          key: 'overdue_days',
          title: '逾期天数',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'status',
          key: 'status',
          title: '负责人审批',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'principal_summary',
          key: 'principal_summary',
          title: '负责人评价',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'lecturer_score',
          key: 'lecturer_score',
          title: '讲师评分',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'lecturer_summary',
          key: 'lecturer_summary',
          title: '讲师评价',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'area_summary',
          key: 'area_summary',
          title: '区域评价',
        },
        // {
        //   width: 100,
        //   fixed: 'right',
        //   title: "操作",
        //   align: "center",
        //   key: "action",
        //   scopedSlots: { customRender: "action" },
        // }
      ],
      data: [],
      actionInfo: {},
      loading: false,
      readOnly: false,
      visible: false,//写评价弹窗
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      stageId: Number(this.$route.query?.stage) || undefined,//阶段id
      planId: '',//计划id
      writeId: '',//写评价id
      writeText: '',//写评价内容

      orgList: undefined,
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.type = this.$store.state.Offline.trainDetails.training_type;
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;
  },
  destroyed() {
  },
  methods: {
    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.id,
      });
      this.orgList = data?.data || undefined;
    },
    // 行动计划列表
    async getData(page, pageSize, isDownload) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id,
        training_type: this.type,
        truename_or_username_or_idcard: this.stuName,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        stage: this.stageId,
        plan_id: this.planId,
        is_download: isDownload ? isDownload : '',
      };
      const data = await getPlanList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload).finally(() => this.loading = false);
      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    // 行动计划标签
    async getTab() {
      if (this.type === 2) {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
        if (!this.orgIds) {
          this.$message.info('请选择组织机构');
          return;
        }
      }
      const params = {
        training_id: this.id,
        training_type: this.type,
        stage: this.stageId,
        org_id: this.orgIds,
        post_id_str: this.postIds,
      };
      const data = await getPlanLabel(params);
      if (!data || data.error_code) {
        return;
      }
      if (data.data.plans.length > 0) {
        this.planTab = (data?.data.plans || []).map((i) => {
          const item = { ...i };
          if (i.diy_content?.length) {
            i.diy_content.forEach((diy, idx) => {
              item[`diy_${idx}`] = diy.content;
            });
          }
          return item;
        });
        this.actionInfo = data?.data.info || {};
        const planTable = [];
        planTable.push(this.planTab[0]);
        this.planData = planTable;
        this.planId = this.planTab[0].id;
        this.getData();
      } else {
        this.data = [];
        this.planTab = [];
        this.planData = [];
        this.pagination = {};
        this.actionInfo = data?.data.info || {};
      }
    },
    //切换标签
    changeTab(id) {
      this.planId = id;
      this.planData = this.planTab.filter(obj => obj.id === id);
      this.getData();
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      this.getTab();
    },
    reset() {
      this.postIds = '';
      this.orgIds = '';
      this.stageId = undefined;
      this.stuName = '';
      this.actionInfo = {};
      this.planData = [];
      this.planTab = [];
      this.data = [];
      this.pagination = {};
    },
    // 导出列表
    download() {
      this.getData('', '', 1);
    },
    // 查看详情
    writeEvaluation(id, text) {
      if (id === 0) {
        this.$message.info('未提交材料,无法评价');
        return;
      }
      this.writeText = text;
      this.visible = true;
      this.writeId = id;
    },
    // 提交评价
    async confirmModal() {
      const params = {
        user_plan_id: this.writeId,
        summary: this.writeText,
      };
      const data = await postPlan(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.writeId = '';
      this.writeText = '';
      this.visible = false;
      this.$message.success(data.message);
      this.getData();
    },
    // 关闭评价弹窗
    closeModal() {
      this.writeId = '';
      this.writeText = '';
      this.visible = false;
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineAction {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;

    span {
      margin-right: 20px;
    }
  }

  .actionDom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    padding: 10px 15px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}

</style>

