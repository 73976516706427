<template>
  <SubmitPage type="student"/>
</template>

<script>
import SubmitPage from './components/SubmitPage';

export default {
  name: 'SubmitStudent',
  components: {
    SubmitPage,
  },
};
</script>

<style scoped lang="scss">

</style>

