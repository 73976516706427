<template>
  <div class="offlineCheck">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item" v-if="type === 1">
        <span>*班次：</span>
        <SelectClasses
          :show-label="false"
          :disabled="readOnly"
          v-model="trainClass"
          :list="classList"
          @select="selectClasses"
        ></SelectClasses>
      </div>
      <div class="item" v-if="type === 2">
        <span>*阶段：</span>
        <a-select v-model="stageId" placeholder="请选择阶段" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of stageList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div class="row row-left">
        <div class="col col-left item">
          <span class="title">岗位：</span>
          <SelectJob
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>

      <div class="row row-left">
        <div class="col col-left">
          <span class="title">*组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            v-model="orgIds"
            :max="trainingType === 2 ? 1 : 0"
            :data="trainingType === 2 ? orgList : undefined"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="actionDom">
      <a-button @click="download2()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered row-key="idcard"
               :scroll="{ x: 1800 }">
        <template slot="idcard" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="idcard"/>
          </div>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="mobile"/>
          </div>
        </template>

        <span class="actionBox" slot="action" slot-scope="text, record">
          <a @click="clockDetails(record)">打卡明细</a>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <DownloadReasonModal ref="downloadReasonModalRef" @saved="download"/>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import SelectClasses from '@/components/select/SelectClasses';
import Common from '@/utils/Common';
import { getTrainClockinList } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';
import { getTrainingOrgTree } from './api/api';
import CiphertextViewer from '@/components/viewer/CiphertextViewer.vue'
import DownloadReasonModal from '@/views/download/record/components/DownloadReasonModal.vue'

export default {
  name: 'offlineCheck',
  components: { SelectOrg, SelectJob, SelectClasses, Pagination, CiphertextViewer, DownloadReasonModal },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
      },
    },
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 0,//培训Type
      classList: [],//班次筛选
      stageList: [],//阶段筛选
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: 190,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
          scopedSlots: { customRender: 'idcard' }
        },
        {
          width: 140,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
          scopedSlots: { customRender: 'mobile' }
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '班次',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'should_clock_num',
          key: 'should_clock_num',
          title: '应打卡总次数',
        },
        {
          width: 130,
          align: 'center',
          dataIndex: 'graduation_clock_num',
          key: 'graduation_clock_num',
          title: '可结业打卡次数',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'yi_clock_num',
          key: 'yi_clock_num',
          title: '实际打卡次数',
        },
        {
          width: 100,
          fixed: 'right',
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      loading: false,
      readOnly: false,
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      trainClass: this.$route.query?.classId || undefined,//班次id
      stageId: Number(this.$route.query?.stage) || undefined,//阶段id

      orgList: undefined,
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.type = this.$store.state.Offline.trainDetails.training_type;
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;
    if (this.type === 2) {
      this.columns = this.columns.filter(obj => obj.key !== 'graduation_clock_num');
      this.columns.find(obj => obj.key === 'classes_name').title = '阶段';
    }
  },
  destroyed() {
  },
  methods: {
    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.id,
      });
      this.orgList = data?.data || undefined;
    },
    // 打卡列表
    async getData(page, pageSize, isDownload, param) {
      if (this.type === 1) {
        if (this.trainClass === undefined) {
          this.$message.info('请选择班次');
          return;
        }
      } else {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
        if (!this.orgIds) {
          this.$message.info('请选择组织机构');
          return;
        }
      }

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id,
        training_type: this.type,
        truename_or_username_or_idcard: this.stuName,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        classes_id_str: this.trainClass,
        stage: this.stageId,
        is_download: isDownload ? isDownload : '',
        ...(param || {})
      };
      const data = await getTrainClockinList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload && param.down_type !== 1).then((data) => {
        if (isDownload) {
          this.$refs.downloadReasonModalRef.stopLoading()
          this.$refs.downloadReasonModalRef.close()
          this.$message.success(param.down_type === 1 ? '申请成功' : '下载成功')
        }

        return data
      }).catch((e) => {
        if (isDownload) {
          this.$refs.downloadReasonModalRef.stopLoading()
          this.$message.error(param.down_type === 1 ? '申请失败' : '下载失败')
        }
        return e
      }).finally(() => this.loading = false);

      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    //选择班次
    selectClasses(event) {
      this.trainClass = event && event.length ? event.join(',') : '';
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      this.getData();
    },
    reset() {
      this.postIds = '';
      this.orgIds = '';
      this.stageId = undefined;
      this.stuName = '';
      this.trainClass = undefined;
      this.data = [];
      this.pagination = {};
    },
    download2() {
      this.$refs.downloadReasonModalRef.show()
    },
    // 导出列表
    download(evt) {
      this.getData('', '', 1, evt || {});
    },
    // 打卡明细
    clockDetails(data) {
      this.$router.push({
        path: '/index/offline/trainCheckDetails',
        query: {
          trainingId: data.training_id,
          userId: data.user_id,
          classesId: data.classes_id,
          stageId: data.stage,
          type: this.type,
          name: data.truename,
          orgname: data.orgname,
          postname: data.postname,
          mobile: data.mobile,
          idcard: data.idcard,
          classesName: data.classes_name,
        },
      });
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineCheck {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionDom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    padding: 5px 15px 10px 20px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}

</style>

