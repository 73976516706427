<template>
  <div class="time-scale-item-wrapper"
       :style="{'height': titleHeight + 'px'}">
    <div class="month"
         :class="{'start': isFirst, 'middle': isMiddle}"
         :style="{height: monthHeight + 'px'}">
      <span v-if="showMonth">{{ month }}</span>
    </div>
    <div class="time-scale-item"
         :style="{'height': (titleHeight - monthHeight) + 'px'}">
      <div class="time-scale-item-inner"
           :class="{'today': isToday}">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import config from '../assets/gantt.config';

export default {
  name: 'TimeScaleItem',
  props: {
    day: {},
    getTimeScales: {},
    min: {},
    max: {},
  },
  computed: {
    isApproved() {
      const min = moment(this.min, 'YYYY-MM-DD');
      const max = moment(this.max, 'YYYY-MM-DD');
      const date = moment(this.day.format('YYYY-MM-DD'), 'YYYY-MM-DD');
      return (
        (min && max && !min.isAfter(date) && !max.isBefore(date)) ||
        (min && !max && !min.isAfter(date)) ||
        (max && !min && !max.isBefore(date))
      );
    },
    month() {
      return this.day.format('YYYY-MM') || '';
    },
    date() {
      return this.day.format('DD') || '';
    },
    isToday() {
      return moment().isSame(this.day.format('YYYY-MM-DD'), 'days');
    },
    showMonth() {
      return this.isFirst || this.isMiddle;
    },
    isFirst() {
      return this.day.format('DD') === '01';
    },
    isMiddle() {
      return this.day.format('DD') === '15';
    },
  },
  data() {
    return {
      titleHeight: config.titleHeight,
      monthHeight: config.monthHeight,
    };
  },
};
</script>

<style scoped lang="scss">
.time-scale-item-wrapper {
  position: relative;
}

.month {
  //position: absolute;
  //top: -100%;
  //left: 0;
  height: 30px;
  padding-left: 10px;
  white-space: nowrap;
  text-align: left;
}

.time-scale-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;

  .time-scale-item-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 29px;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 14px;
    font-family: DINAlternate-Bold, DINAlternate, sans-serif;
    font-weight: bold;
    color: #666666;

    &.today {
      color: #FFFFFF;
      background: linear-gradient(90deg, #334FA0 0%, #3C85B8 100%);
    }
  }
}
</style>
