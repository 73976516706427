import request from '@/utils/Request';

// 取消报名
export function cancelSignUp(data) {
  return request({
    url: '/offline/admin/apply/cancelApply',
    method: 'post',
    data,
  });
}

// 更换班次的班次列表
export function getAdjustClassList(params) {
  return request({
    url: '/offline/admin/apply/replaceClassesList',
    method: 'get',
    params,
  });
}

// 更换班次
export function saveAdjustClassList(data) {
  return request({
    url: '/offline/admin/apply/replaceClasses',
    method: 'post',
    data,
  });
}



