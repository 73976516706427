<template>
  <div v-if="customList.length">
    <div v-for="(item, index) of customList" :key="index">
      <a-form-model
        :model="item"
        :ref="'ruleForm-' + index"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item prop="title" style="margin-top:20px;">
          <div class="tem-box">
            <a-input v-model="item.title" placeholder="请填写模版名称"></a-input>
            <span class="close" @click="deleteTemInfo(index)">
              <a-icon type="close" />
            </span>
          </div>
        </a-form-model-item>
        <div class="class-box">
          <TemCard
            ref="temCard"
            :infoList="item.info || []"
          />
          <a-button type="primary" @click="infoInit(item, 1)">添加自定义字段</a-button>
          <a-button type="primary" @click="addPic(item)">添加图片组</a-button>
        </div>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import TemCard from './TemCard'

const rules = {
  title: [{ required: true, message: '请输入板块名称!', trigger: 'change' }],
}
export default {
  components: {
    TemCard
  },
  props: {
    customList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      rules,
    }
  },
  methods: {
    validateForm() {
      const arr = [];
      this.customList.forEach((item, index) => {
        this.$refs[`ruleForm-${index}`].forEach((item) => {
          item.validate((valid) => {
            arr.push(valid);
          });
        });
      });
      if (arr.includes(false)) {
        return false;
      }
      return true;
    },
    infoInit(item, type) {
      item.info.push(type === 1 ? {
        type,
        key: '',
        value: ''
      } : {
        type,
        img_key: '',
        img_value: ''
      })
      console.log(this.customList);
    },
    addPic(item) {
      this.infoInit(item, 2)
    },
    deleteTemInfo(index) {
      this.$emit('deleteTemInfo', index)
    },
  },
}
</script>
<style lang="scss" scoped>
.tem-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close {
  margin-left: 81px;
}
</style>
