<template>
  <a-modal title="添加新的人员表"
           :visible="shown"
           :confirm-loading="loading"
           :mask-closable="false"
           :width="600"
           :centered="true"
           @cancel="close">
    <a-form-model label-align="right"
                  ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 6}" :wrapper-col="{span: 12}">
      <a-form-model-item label="名单名称" prop="title">
        <a-input v-model="form.title" :disabled="uploading" placeholder="名单名称（不可重复）"></a-input>
      </a-form-model-item>
      <a-form-model-item label="上传人员名单" prop="fileList">
        <a-upload
          :disabled="uploading"
          name="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :file-list="form.fileList"
          :multiple="false"
          :before-upload="beforeUpload"
          :custom-request="handleRequestUpload">
          <a-button>上传</a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item>
        <span>点击</span>
        <a :href="templateUrl" target="_blank">此处</a>
        <span>下载名单</span>
      </a-form-model-item>
    </a-form-model>

    <template slot="footer">
      <a-button @click="close">关闭</a-button>
      <a-button type="primary" :loading="uploading" @click="save">保存</a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import { uploadParticipationPeopleTemplate } from '@/api/statistics/participation/Index';

export default {
  name: 'AddPeopleModal',
  computed: {
    ...mapState({
      templateUrl: state => state.StatisticsParticipation.peopleTemplateUrl,
      peopleList: state => state.StatisticsParticipation.peopleList,
    }),
  },
  data() {
    const titleValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error(rule?.message || '请填写内容'));
      } else if (this.peopleList.findIndex(i => i.title === this.form.title) > -1) {
        callback(new Error(rule?.message || '该名称已存在'));
      } else {
        callback();
      }
    };
    const fileListValidator = (rule, value, callback) => {
      if (!this.form.fileList?.length) {
        callback(new Error(rule?.message || '请选择文件'));
      } else {
        callback();
      }
    };

    return {
      loading: false,
      shown: false,

      form: {
        title: '',
        fileList: [],
      },
      uploading: false,

      rules: {
        title: [
          { required: true, whitespace: true, message: '请填写名单名称', trigger: 'blur' },
          { validator: titleValidator, message: '该名称已存在', trigger: 'blur' },
        ],
        fileList: [
          { validator: fileListValidator, trigger: 'change' },
        ],
      },
    };
  },
  created() {
    this.getTemplateUrl();
  },
  methods: {
    getTemplateUrl() {
      if (!this.templateUrl) {
        this.$store.dispatch('StatisticsParticipation/getTemplateUrl');
      }
    },
    show() {
      this.shown = true;

      this.getTemplateUrl();
    },
    close() {
      this.shown = false;

      this.form = {
        title: '',
        fileList: [],
      };
      this.$refs.formRef.resetFields();
    },

    save() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.upload();
        }
      });
    },

    async upload() {
      if (this.uploading) {
        return;
      }
      this.uploading = true;

      const params = new FormData();
      params.append('file', this.form.fileList[0]);
      params.append('template_title', this.form.title);

      const data = await uploadParticipationPeopleTemplate(params);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '上传失败');
        return;
      }

      this.$message.success(data?.message || '保存成功');
      this.$store.dispatch('StatisticsParticipation/getParticipationPeopleList');
      this.uploading = false;
      this.close();
    },

    beforeUpload(file) {
      this.form = {
        fileList: [file],
        title: (file?.name || '').replace(/\.xlsx$/g, ''),
      };

      this.$nextTick(() => this.$refs.formRef.validate());
      return false;
    },
    // 自定义上传
    async handleRequestUpload(options = {}) {
      const { file, onError, onSuccess } = options;
      if (this.uploading) {
        onError('Uploading', 'Uploading');
        return;
      }
      this.uploading = true;
      this.form = { ...this.form, fileList: [file] };

      const params = new FormData();
      params.append('file', file);
      params.append('template_title', this.form.title);

      const data = await uploadParticipationPeopleTemplate(params);
      if (!data || data.error_code) {
        onError(data?.message || '上传失败');
        return;
      }

      onSuccess(params);
      this.$store.dispatch('StatisticsParticipation/getParticipationPeopleList');
      this.uploading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
