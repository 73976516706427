import { baseUrl } from '@/Config';
import { stringify } from 'qs';
import store from '@/store/index';
import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/download/index';

// 业务详情-提交材料统计-获取材料标签
export function getMaterialLabel(params) {
  return request({
    url: '/offline/admin/material/label',
    method: 'get',
    params,
  });
}

// 业务详情-学员提交材料统计
export function getStudentMaterialList(params) {
  return request({
    url: '/offline/admin/material/index',
    method: 'get',
    params,
  });
}

// 业务详情-学员提交材料统计 -- 导出
export function getStudentMaterialListDownload(params) {
  const url = '/offline/admin/material/index'
  const path = `${baseUrl}${url}?${stringify(params)}`;
  if (params.down_type === 1) {
    return request({
      url,
      method: 'GET',
      params
    })
  }
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 业务详情-讲师提交材料统计
export function getTeacherMaterialList(params) {
  return request({
    url: '/offline/admin/material/teacher',
    method: 'get',
    params,
  });
}

// 业务详情-讲师提交材料统计 -- 导出
export function getTeacherMaterialListDownload(params) {
  const url = '/offline/admin/material/teacher'
  const path = `${baseUrl}${url}?${stringify(params)}`;
  if (params.down_type === 1) {
    return request({
      url,
      method: 'GET',
      params
    })
  }
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 业务详情-提交材料统计+写评价
export function postSummary(data) {
  return request({
    url: '/offline/admin/material/summary',
    method: 'POST',
    data,
  });
}
