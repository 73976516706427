<template>
  <div class="offlineSummary">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item" v-if="type === 2">
        <span>*阶段：</span>
        <a-select v-model="stageId" placeholder="请选择阶段" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of stageList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div class="row row-left">
        <div class="col col-left item">
          <span class="title">*岗位：</span>
          <SelectJob
            :mode="'default'"
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>
      <div class="row row-left">
        <div class="col col-left">
          <span class="title">*组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            v-model="orgIds"
            :max="trainingType === 2 ? 1 : 0"
            :data="trainingType === 2 ? orgList : undefined"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
        </div>
      </div>
    </div>
    <div class="actionDom">
      <span class="empty" style="font-size: 12px; color: #999999;">导出后将按照岗位进行分页</span>
      <a-button @click="download2()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="newColumns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="idcard" :scroll="{ x: 2000 }">
        <template slot="idcard" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="idcard"/>
          </div>
        </template>
        <template slot="mobile" slot-scope="text, record">
          <div class="flex">
            <CiphertextViewer :user-id="record.user_id" :data="record" field="mobile"/>
          </div>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>

    <DownloadReasonModal ref="downloadReasonModalRef" @saved="download"/>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import Common from '@/utils/Common';
import { getResultList } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';
import { getTrainingOrgTree } from './api/api';
import CiphertextViewer from '@/components/viewer/CiphertextViewer.vue'
import DownloadReasonModal from '@/views/download/record/components/DownloadReasonModal.vue'

export default {
  name: 'offlineSummary',
  components: {
    CiphertextViewer,
    DownloadReasonModal,
    SelectOrg,
    SelectJob,
    Pagination
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
      },
    },
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 1,//培训Type
      classList: [],//班次筛选
      stageList: [],//阶段筛选
      addColumns: [],
      newColumns: [],
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: 190,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
          scopedSlots: { customRender: 'idcard' }
        },
        {
          width: 140,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
          scopedSlots: { customRender: 'mobile' }
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '班次',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'clockin',
          key: 'clockin',
          title: '总打卡次数',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'homework_sub',
          key: 'homework_sub',
          title: '作业提交情况',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'homework_overdue_days',
          key: 'homework_overdue_days',
          title: '作业逾期情况',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'certification_grade',
          key: 'certification_grade',
          title: '认证成绩',
        },
      ],
      data: [],
      loading: false,
      readOnly: false,
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      trainClass: this.$route.query?.classId || '',//班次id
      stageId: Number(this.$route.query?.stage) || undefined,//阶段id

      orgList: undefined,
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.type = this.$store.state.Offline.trainDetails.training_type;
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;
    if (this.type === 2) {
      this.columns = this.columns.filter(obj => obj.key !== 'certification_grade');
      this.columns.find(obj => obj.key === 'classes_name').title = '阶段';
    }
    // this.getData(1, this.pagination.defaultPageSize);
  },
  destroyed() {
  },
  methods: {
    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.id,
      });
      this.orgList = data?.data || undefined;
    },
    async getData(page, pageSize, isDownload, param) {
      if (!this.postIds) {
        this.$message.info('请选择岗位');
        return;
      }
      if (!this.orgIds) {
        this.$message.info('请选择组织机构');
        return;
      }
      if (this.type === 2) {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
      }
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id,
        training_type: this.type,
        truename_or_username_or_idcard: this.stuName,
        stage: this.stageId,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        is_download: isDownload ? isDownload : '',
        ...(param || {}),
      };
      const data = await getResultList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload && param.down_type !== 1).then((data) => {
        if (isDownload) {
          this.$refs.downloadReasonModalRef.stopLoading()
          this.$refs.downloadReasonModalRef.close()
          this.$message.success(param.down_type === 1 ? '申请成功' : '下载成功')
        }

        return data
      }).catch((e) => {
        if (isDownload) {
          this.$refs.downloadReasonModalRef.stopLoading()
          this.$message.error(param.down_type === 1 ? '申请失败' : '下载失败')
        }
        return e
      }).finally(() => this.loading = false);
      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      if (this.data.length > 0) {
        const newArr = [];
        const oldArr = JSON.parse(JSON.stringify(this.columns));
        this.data[0].column.forEach((item, index) => {
          const params = {
            key: index,
            width: 120,
            // fixed: 'right',
            align: 'center',
            dataIndex: 'column_' + index,
            title: item.title + '(' + item.score + ')',
          };
          newArr.push(params);
        });
        oldArr.push(...newArr);
        this.addColumns = newArr;
        this.newColumns = oldArr;
      }
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      this.getData();
    },

    download2() {
      if (!this.postIds) {
        this.$message.info('请选择岗位');
        return;
      }
      if (!this.orgIds) {
        this.$message.info('请选择组织机构');
        return;
      }
      if (this.type === 2) {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
      }
      this.$refs.downloadReasonModalRef.show()
    },
    // 导出列表
    download(evt) {
      this.getData('', '', 1, evt || {});
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineSummary {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionDom {
    text-align: right;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    padding: 5px 15px 10px 20px;

    .pass {
      color: green;
    }

    .nopass {
      color: red;
    }
  }
}

</style>

