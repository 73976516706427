<template>
  <div class="offlineCheckDetails">
    <a-button class="back" @click="back">返回</a-button>
    <div class="info">
      <div class="item row row-left">
        <span>姓名：{{ details.name }}</span>
        <span>所在经销商：{{ details.orgname }}</span>
        <span>岗位：{{ details.postname }}</span>
      </div>
      <div class="item row row-left">
        <span>电话：{{ details.mobile }}</span>
        <span>身份证号：{{ details.idcard }}</span>
        <span v-if="details.classesId !== '0'">班次：{{ details.classesName }}</span>
        <span v-if="details.stageId">阶段：{{ details.classesName }}</span>
      </div>
    </div>
    <div class="table">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered row-key="day">
        <span slot="day" slot-scope="text">{{ text }}</span>
        <span slot="is_clock" slot-scope="text">{{ text }}</span>
        <span slot="clock_time" slot-scope="text">{{ text }}</span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import Common from '@/utils/Common';
import { getTrainClockinLogs } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';

export default {
  name: 'offlineCheckDetails',
  components: { Pagination },
  data() {
    return {
      columns: [
        {
          width: '',
          align: 'center',
          dataIndex: 'day',
          key: 'day',
          title: '打卡日期',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'is_clock',
          key: 'is_clock',
          title: '是否打卡',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'clock_time',
          key: 'clock_time',
          title: '打卡时间',
        },
      ],
      data: [],
      details: {},
      loading: false,
      readOnly: false,
      pagination: getPagination(),
    };
  },
  created() {
    this.details = this.$route.query;
    this.getData(1, this.pagination.defaultPageSize);
  },
  destroyed() {
  },
  methods: {
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.details.trainingId || 1,
        user_id: this.details.userId || 1,
        classes_id: this.details.classesId || 1,
        stage: this.details.stageId || '',
        training_type: this.details.type || 1,
      };
      const data = await getTrainClockinLogs({
        page: page,
        per_page: pageSize,
        ...params,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
    },
    reset() {
    },
    // 返回
    back() {
      this.$router.back();
    },
    // 打卡明细
    clockDetails() {
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineCheckDetails {
  padding: 5px 15px 10px 20px;

  .info {
    padding-bottom: 20px;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;

      span {
        padding: 0 10px;
      }
    }
  }

  .back {
    margin: 20px;
  }
}
</style>
