<template>
  <a-modal v-model="shown"
           :destroy-on-close="true"
           :mask-closable="false"
           :confirm-loading="loading"
           title="填写评价"
           :width="800"
           :centered="true"
           @ok="submit"
           @cancel="close">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="评价内容">
        <a-textarea v-model="summary"
                    :disabled="loading"
                    placeholder="请填写评价内容"
                    :auto-size="{ minRows: 3, maxRows: 8 }"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  postSummary,
} from '../api/submitMaterial';

export default {
  name: 'WriteCommentModal',
  data() {
    return {
      loading: false,
      shown: false,

      materialId: '',
      summary: '',
    };
  },
  methods: {
    show({ materialId, summary } = {}) {
      this.materialId = materialId || '';
      this.summary = summary || '';

      this.shown = true;
    },
    close() {
      this.shown = false;
    },

    async submit() {
      if (!this.summary) {
        this.$message.info('请填写评价内容');
        return;
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await postSummary({
        user_material_id: this.materialId,
        summary: this.summary,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data.message || '保存失败');
        return;
      }

      this.$message.success(data.message || '保存成功');
      this.$emit('success');
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
