import request from '@/utils/Request';

// 考试配置——列表查询
export function getExamList(params) {
  return request({
    url: '/offline/admin/training/test/list',
    method: 'get',
    params,
  });
}

// 考试配置——新增
export function addExam(data) {
  return request({
    url: '/offline/admin/training/test/add',
    method: 'post',
    data,
  });
}

// 考试配置——修改
export function editExam(data) {
  return request({
    url: '/offline/admin/training/test/save',
    method: 'post',
    data,
  });
}

// 考试配置——删除
export function deleteExam(data) {
  return request({
    url: '/offline/admin/training/test/del',
    method: 'delete',
    data,
  });
}

// 考试配置——详情
export function getExamDetail(params) {
  return request({
    url: '/offline/admin/training/test/show',
    method: 'get',
    params,
  });
}

// 考试配置——班次列表
export function getClassList(params) {
  return request({
    url: '/offline/admin/training/classlist',
    method: 'get',
    params,
  });
}

// 考试配置——参考岗位下拉
export function getPostList(params) {
  return request({
    url: '/offline/admin/training/test/postlist',
    method: 'get',
    params,
  });
}

// 考试配置——试卷列表
export function getTestPaperList() {
  return request({
    url: '/offline/admin/training/test/testpaper',
    method: 'get'
  });
}
