<template>
  <div>
    <a-upload
      name="file"
      action="/public/conf/upload"
      :headers="{ 'X-Auth-Token': $store.state.User.token || '' }"
      list-type="picture-card"
      :file-list="fileList"
      :remove="change"
      @change="uploadChange"
    >
      <div v-if="fileList.length < max">
        <a-icon :type="uploading ? 'loading' : 'plus'" />
        <div class="ant-upload-text">请上传图片</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'ClassUploadPic',
  props: {
    customFileList: {
      type: Array,
      default: () => [],
    },
    // 最大上传数量
    max: {
      type: Number,
      default: 0
    }
  },
  watch: {
    customFileList: {
      handler(val) {
        if (val && val.length) {
          this.fileList = val
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      uploading: false,
    };
  },
  methods: {
    change() {
      this.$emit('uploadChange', this.fileList)
    },
    uploadChange(info) {
      this.fileList = [...(info.fileList || [])]
      if (info.file.status === 'uploading') {
        this.uploading = true;
      }
      if (info.file.status === 'done') {
        this.uploading = false;
        this.$message.success('文件上传成功');
        this.fileList.forEach((i) => {
          i.file_resource_id =
            i.file_resource_id || i.response?.data?.file_resource_id;
        });
        this.change();
      }
      if (info.file.status === 'error') {
        this.uploading = false;
        this.$message.success('文件上传失败');
        this.change();
      }
      if (info.file.status === 'removed') {
        this.uploading = false;
        this.$message.success('文件删除成功');
        this.fileList.forEach((i) => {
          i.file_resource_id =
            i.file_resource_id || i.response?.data?.file_resource_id;
        });
        this.change();
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      })
    },
  },
};
</script>
