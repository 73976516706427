<template>
  <a-modal v-model="visible" :title="title"
           :mask-closable="false" @ok="handleOk">
    <a-form class="ant-advanced-search-form"
            :form="form"
            @submit="handleOk"
            v-bind="formItemLayout">
      <a-form-item label="调研名称">
        <a-input placeholder="请输入" v-decorator="['title', validatorRules.title]"/>
      </a-form-item>
      <a-form-item label="问卷链接">
        <a-input placeholder="请输入" v-decorator="['research_url', validatorRules.research_url]"/>
      </a-form-item>
      <a-form-item label="弹出位置">
        <a-select placeholder="请选择" v-decorator="['alert', validatorRules.alert]" class="w700">
          <a-select-option v-for="item in alertList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="trainingType === 2" label="选择阶段">
        <a-select placeholder="请选择阶段" v-decorator="['stage', validatorRules.stage]" class="w700">
          <a-select-option v-for="item in stageList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { surveyDetail, addSurvey } from '@/api/config';
import { TRAIN_LIST, COUNSELING } from '../constants';
import Common from '@/utils/Common';
import { mapState } from 'vuex';

export default {
  name: 'addSurveyLayer',
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
      stageList: (state) => state.Offline.stage,
    }),
    alertList() {
      return this.trainingType === 1 ? TRAIN_LIST : COUNSELING;
    },
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (type === 2) {
          this.validatorRules.stage = {
            rules: [{ required: true, message: '请选择阶段!' }],
          };
        } else if (this.validatorRules.stage) {
          delete this.validatorRules.stage;
        }
      },
    },
  },
  data() {
    return {
      TRAIN_LIST,
      COUNSELING,
      visible: false,
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      data: [],
      selectAlertList: [],
      validatorRules: {
        title: {
          rules: [{ required: true, message: '请输入调研名称!' }],
        },
        research_url: {
          rules: [{ required: true, message: '请输入问卷链接!' }],
        },
        alert: {
          rules: [{ required: true, message: '请选择弹出位置!' }],
        },
      },
      isAdd: false,
      id: '', //调研id
      training_id: '', //培训列表id
      title: '添加调研',
    };
  },
  methods: {
    openMe(data) {
      this.visible = true;
      this.isAdd = data && data.id ? false : true;
      this.training_id = this.$route.query.id;
      this.title = data && data.id ? '编辑调研' : '添加调研';
      if (data && data.id) {
        this.id = data.id;
        this.getData(data.id);
        return;
      }
      this.form.resetFields();
    },
    async getData(id) {
      this.loading = true;
      const { data } = await surveyDetail(id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      this.form.setFieldsValue({
        title: data.title,
        alert: data.alert + '',
        research_url: data.research_url,
        stage: data.stage || undefined,
      });
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.training_id = this.training_id;
          values.id = this.isAdd ? '' : this.id;
          this.save(Common.cleanObject(values));
        }
      });
    },
    async save(params) {
      this.loading = true;
      const data = await addSurvey(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.visible = false;
      this.$emit('success');
      this.$message.success({ content: data?.message || '保存成功' });
    },
  },
};
</script>

<style scoped>
</style>
