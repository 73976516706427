
const key = "5UVBZ-AYWL4-IJFUJ-XRMN7-UFKJ3-BHFOA"
import { bdBaseUrl } from '@/Config';
import { jsonp } from 'vue-jsonp'
// 关键词搜索提示
const suggestion = (params) => {
  return jsonp(`${bdBaseUrl}ws/place/v1/suggestion`, {
    ...params,
    key: key,
    output: 'jsonp'
  })

}

// 地址解析
const geocoder = (params) => {
  params = params || {};
  params.key = key;
  return jsonp(`${bdBaseUrl}ws/geocoder/v1/`, {
    ...params,
    key: key,
    output: 'jsonp'
  });
}
export { suggestion, geocoder }