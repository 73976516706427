<template>
  <div class="offlineFormDetail">
    <a-button class="backBtn" @click="back">返回</a-button>
    <div class="titleBox">
      <div class="title">表单名称</div>
      <a-input v-model="formTitle" placeholder="请输入表单名称"></a-input>
    </div>
    <div class="formBox">
      <div
        class="item"
        v-for="(ques, index) in formList.filter(
          (i) => !i.hasOwnProperty('is_del')
        )"
        :key="index">
        <div class="itemBasic">
          <div class="name">第{{ index + 1 }}题</div>
          <div class="type">
            <a-select
              class="select"
              v-model="ques.type"
              @change="typeChange(ques.type, index)">
              <a-select-option
                v-for="item of typeList"
                :key="item.id"
                :value="item.type">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <div class="content">
            <a-input
              class="input"
              v-model="ques.stem"
              placeholder="请输入题干"></a-input>
          </div>
          <a-icon type="close" @click="delQues(ques, index)"/>
        </div>
        <template v-if="ques.options">
          <div
            class="itemOption"
            v-for="(obj, num) in ques.options.filter(
              (i) => !i.hasOwnProperty('is_del')
            )"
            :key="obj.id">
            <div class="tit">选项{{ toLetter(num) }}</div>
            <a-input
              class="input"
              v-model="obj.title"
              placeholder="请输入选项内容"></a-input>
            <a-icon type="close" @click="delOpt(obj, index, num)"/>
          </div>
        </template>
        <a-button
          class="addOpts"
          type="primary"
          v-if="ques.type === 'single' || ques.type === 'multiple'"
          @click="addNewOpt(index)">
          添加选项
        </a-button>
      </div>
      <a-button class="addForms" type="primary" @click="addNewForm">添加题目</a-button>
    </div>
    <a-button class="submitBtn" type="primary" @click="submit">
      提 交
    </a-button>
  </div>
</template>

<script>
import {
  copyForms,
  addForms,
  editForms,
} from './api';

export default {
  name: 'offlineFormDetail',
  data() {
    return {
      typeList: [
        { id: 0, title: '单选题', type: 'single' },
        { id: 1, title: '多选题', type: 'multiple' },
        { id: 2, title: '问答题', type: 'fillIn' },
        { id: 3, title: '时间题', type: 'datetime' },
        { id: 4, title: '文件题', type: 'upload' },
      ],
      id: this.$route.query.id,
      type: this.$route.query.type,
      formList: [],
      formTitle: '',
      typeId: 1,
      contentText: '',
      option: '',
    };
  },
  created() {
    this.getData();
  },
  destroyed() {
  },
  methods: {
    // 获取数据
    getData() {
      if (this.type === 'new') {
        return;
      }
      this.copyForm(this.id);
    },
    // 复制/查看表单
    async copyForm(id) {
      const params = {
        id: id,
      };
      const data = await copyForms(params);
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.formTitle = data.data.title;
      this.formList = data.data.questions;
    },
    // 题型选择
    typeChange(e, index) {
      if (e === 'single' || e === 'multiple') {
        this.formList[index].type = e;
        this.formList[index].options = [];
        this.formList[index].options.push({ title: '' });
      } else {
        this.formList[index].options = [];
      }
    },
    // 添加题目
    addNewForm() {
      this.formList.push({
        // form_id:this.id,
        // id:'',
        options: [],
        stem: '',
        type: 'fillIn',
      });
    },
    // 添加选项
    addNewOpt(index) {
      this.formList[index].options.push({
        title: '',
      });
    },
    // 删除选项
    delQues(obj, index) {
      if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
        obj.is_del = 1;
      } else {
        this.formList.splice(index, 1);
      }
      this.$forceUpdate();
    },
    // 删除题目
    delOpt(obj, index, num) {
      if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
        obj.is_del = 1;
      } else {
        this.formList[index].options.splice(num, 1);
      }
      this.$forceUpdate();
    },
    // 数字转字母
    toLetter(num) {
      const letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
      return letter[num];
    },
    // 提交编辑
    async submit() {
      for (let j = 0; j < this.formList.length; j++) {
        if (this.formList[j].stem === '') {
          this.$message.error('缺少题干');
          return;
        }
        if (this.formList[j].options.length > 0 && this.formList[j].is_del !== 1) {
          for (let i = 0; i < this.formList[j].options.length; i++) {
            if (this.formList[j].options[i].title === '' && this.formList[j].options[i].is_del !== 1) {
              this.$message.error('缺少选项内容');
              return;
            }
          }
        }
      }
      if (this.formTitle === '') {
        this.$message.error('缺少表单名称');
        return;
      }
      if (this.formList.length === 0) {
        this.$message.error('缺少题目');
        return;
      }
      let postList = JSON.parse(JSON.stringify(this.formList));
      postList.forEach((obj, index) => {
        obj.position = index + 1;
        delete obj.form_id;
      });
      if (this.type === 'edit') {
        const params = {
          id: this.id,
          title: this.formTitle,
          info: postList,
        };
        this.$confirm({
          content: () => <div>确认提交？</div>,
          onOk: async () => {
            const data = await editForms(params);
            if (!data || data.error_code) {
              this.$message.error(data.message);
              return;
            }
            this.$message.success(data.message);
            this.$router.back();
          },
        });
      } else {
        postList.forEach((obj) => {
          delete obj.id;
          obj.options.forEach((item) => {
            delete item.id;
            delete item.question_id;
          });
        });
        const params = {
          title: this.formTitle,
          info: postList,
        };
        this.$confirm({
          content: () => <div>确认提交？</div>,
          onOk: async () => {
            const data = await addForms(params);
            if (!data || data.error_code) {
              this.$message.error(data.message);
              return;
            }
            this.$message.success(data.message);
            this.$router.back();
          },
        });
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.offlineFormDetail {
  width: 800px;

  .backBtn {
    margin: 10px 30px;
  }

  .titleBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      font-size: 20px;
      margin: 10px 30px;
    }

    input {
      max-width: 300px;
    }
  }

  .formBox {
    margin: 10px 30px;

    .item {
      margin-bottom: 20px;

      .itemBasic {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 10px;

        .name {
          padding: 0 10px;
        }

        .type {
          padding: 0 10px;

          .select {
            width: 100px;
          }
        }

        .content {
          padding: 0 10px;

          .input {
            min-width: 500px;
          }
        }
      }

      .itemOption {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 114px;
        margin-bottom: 10px;

        .tit {
          width: 60px;
        }

        .input {
          width: 400px;
          margin: 0 10px;
        }
      }

      .addOpts {
        margin-left: 185px;
      }
    }

    .addForms {
      margin-left: 185px;
    }
  }

  .submitBtn {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
