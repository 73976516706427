<template>
  <div class="trainDetails">
    <div class="title">
      <div class="col-left trainName">
        <a-button class="backBtn" @click="back()">返回</a-button>
        {{ data.title }}
      </div>
      <div class="col col-left trainInfo" v-if="data.training_type === 1">
        <span class="sub">培训日期：</span><span>{{ data.start_time }}</span> <span class="sub">至</span>
        <span>{{ data.end_time }}</span> <span class="sub">培训地点：</span> <span>{{ data.address }}</span>
      </div>
    </div>
    <div class="row row-left">
      <div class="col col-left col-narrow">
        <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
          <a-menu-item v-for="item of menus" :key="item.id">
            {{ item.title }}
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="row full-width">
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { trainDetail } from '@/utils/Navs/OfflineNav';
import { getTrainingType } from '../config/api/api';

export default {
  name: 'trainDetails',
  computed: {
    ...mapState({
      options: state => state.Offline.trainDetails,
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  watch: {
    '$route.query.id'(id) {
      this.id = id || this.id;
    },
    '$route.query.classId'(id) {
      this.classId = id || this.classId;
    },
    '$route.query.stageId'(id) {
      this.stageId = id || this.stageId;
    },
    '$route.query.orgId'(id) {
      this.orgId = id || this.orgId;
    },
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      classId: this.$route.query?.classId || '',//看板传过来的班次id
      stageId: this.$route.query?.stage || 1,//看板传过来的阶段id
      orgId: this.$route.query?.orgId || 1,//看板传过来的组织机构id
      menus: trainDetail,
      selectedMenu: ['1'],
    };
  },
  async created() {
    this.id = this.$route.query?.id || null;
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.data = JSON.parse(JSON.stringify(this.$store.state.Offline.trainDetails));
    if (this.data.training_type === 1) {
      // 培训业务不显示 行动统计
      this.menus = this.menus.filter(obj => obj.id !== '7');
    }
    if (this.data.training_type === 2) {
      // 辅导业务不显示 直播
      this.menus = this.menus.filter(obj => obj.id !== '4');
    }

    this.getTrainingType();
  },
  mounted() {

  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.name || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.executeSelection(to?.name || '');
    next();
  },
  beforeDestroy() {
    this.$store.dispatch('Common/getOrgTree');
  },
  methods: {
    async getTrainingType() {
      const data = await getTrainingType({
        training_id: this.id,
      });
      this.$store.commit('Offline/updateTrainType', data.data?.type || 2);
      this.$store.commit('Offline/updateIsAppoint', data.data?.is_appoint || 0);
      this.$store.commit('Offline/updateIsCertIssue', data.data?.is_cert_issue);
      this.$store.commit('Offline/updateStage', data.data?.stage || []);
    },
    // 返回
    back() {
      if (Object.hasOwnProperty.call(this.$route.query, 'stageId') || Object.hasOwnProperty.call(this.$route.query, 'classId') || Object.hasOwnProperty.call(this.$route.query, 'orgId')) {
        this.$router.push('/index/schedule/gantt');
      } else {
        this.$router.push('/index/offline/training/list');
      }
    },
    executeSelection(name) {
      this.menus.forEach(menu => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      this.id = this.$route.query?.id || null;
      this.classId = this.$route.query?.classId || null;
      this.stageId = this.$route.query?.stage || null;
      this.orgId = this.$route.query?.orgId || null;
      const path = this.getPath(key) + (this.id ? `?id=${this.id}` : '') + (this.classId ? `&classId=${this.classId}` : '') + (this.stageId ? `&stage=${this.stageId}` : '') + (this.orgId ? `&orgId=${this.orgId}` : '');
      this.$router.replace(path);
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">
.trainDetails {
  width: 100%;

  .title {
    padding-left: 30px;

    .trainName {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding-bottom: 10px;

      .backBtn {
        margin-right: 10px;
      }
    }

    .sub {
      color: #999;
    }
  }
}
</style>
