<template>
  <j-modal
    centered
    :title="title"
    :width="width"
    :visible="visible"
    :mask-closable="false"
    switchFullscreen
    @ok="saveTable"
    @cancel="cancelTable"
    cancelText="关闭"
  >
    <tx-map ref="map" @success="onSuccess" :latLng="latLng"></tx-map>
  </j-modal>
</template>

<script>
export default {
  name: 'selectMap',
  components: {
    'j-modal': () => import('@/components/JModal'),
    'tx-map': () => import('@/components/map'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    latLng: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      width: 500,
      visible: false,
      result: {},
    };
  },
  methods: {
    openMe() {
      this.visible = true;
    },
    saveTable() {
      // 点击确定时 关闭当前弹窗
      this.visible = false;
      this.$emit('success', this.result);
    },
    cancelTable() {
      this.visible = false;
    },
    onSuccess(value) {
      this.result = value;
    },
  },
};
</script>

<style scoped>
</style>
