<template>
  <div class="pd20">
    <div class="flex flex-start mb-20">
      <span class="mr-15">预约审批</span>
      <div v-for="item in appointmentList" :key="item.value">
        <a-checkbox v-model="item.checked" class="ml-10" @change="checkChange(item)">
          {{ item.label }}
        </a-checkbox>
        <a-input-number v-model="item.num"
                        :disabled="!item.checked"
                        :min="0"
                        class="ml-10 mr-10"
                        @change="numChanged(item)"/>
        <span class="mr-10">天</span>
      </div>
      <a-button @click="addTeacher()" type="primary">
        添加讲师
      </a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             bordered
             :loading="loading"
             :pagination="false"
             row-key="id"
             class="table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button type="link" size="small" @click="editTeacher(record, data)">编辑</a-button>
        <a-button type="link" size="small" @click="operate(record)">{{ +record.status === 1 ? '下架' : '上架' }}</a-button>
        <a-button type="link" size="small" @click="deleteItem(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>

    <SaveButtons @handleSubmit="save" @saveAndNext="saveAndNext"></SaveButtons>
    <AddLecturerLayer ref="layer" @success="getData"></AddLecturerLayer>
    <AddTeacherModal ref="addTeacherModalRef" :training-id="id" @saved="teacherSaved"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  getTeacherList,
  operateTeacher,
  deleteTeacher,
  getTeacherAppointment,
  saveTeacherAppointment,
} from './api/config.teacher';

import Pagination, { getPagination, updatePagination } from '@/components/Pagination';
import AddLecturerLayer from './components/addLecturerLayer';
import SaveButtons from './components/saveButtons';
import AddTeacherModal from './components/AddTeacherModal';

export default {
  name: 'instructorAppointment',
  components: {
    Pagination,
    AddLecturerLayer,
    SaveButtons,
    AddTeacherModal,
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
    }),
  },
  data() {
    return {
      loading: false,
      id: '',
      appointmentId: '',
      data: [],
      pagination: getPagination(),

      columns: [{
        title: '姓名',
        dataIndex: 'teacher_name',
        scopedSlots: { customRender: 'teacher_name' },
        width: 80,
        ellipsis: true,
      }, {
        title: '可选组织机构',
        dataIndex: 'org_str',
        scopedSlots: { customRender: 'org_str' },
        width: 100,
        ellipsis: true,
      }, {
        title: '描述',
        dataIndex: 'teacher_desc',
        scopedSlots: { customRender: 'teacher_desc' },
        ellipsis: true,
      }, {
        title: '视频数量',
        dataIndex: 'test_video_num',
        scopedSlots: { customRender: 'test_video_num' },
        customRender: (text) => {
          return text + '个';
        },
        width: 100,
        ellipsis: true,
      }, {
        title: '课件数量',
        dataIndex: 'courseware_num',
        scopedSlots: { customRender: 'courseware_num' },
        customRender: (text) => {
          return text + '个';
        },
        width: 100,
        ellipsis: true,
      }, {
        title: '上架状态',
        dataIndex: 'status_txt',
        scopedSlots: { customRender: 'status_txt' },
        width: 100,
        ellipsis: true,
      }, {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
        width: 180,
        ellipsis: true,
      }],
      appointmentList: [{
        value: 'is_headquarters',
        numType: 'headquarters_release_day',
        label: '本部审批',
        num: 0,
        checked: false,
      }, {
        value: 'is_region',
        numType: 'region_release_day',
        label: '大区审批',
        num: 0,
        checked: false,
      }, {
        value: 'is_village',
        numType: 'village_release_day',
        label: '小区审批',
        num: 0,
        checked: false,
      }, {
        value: 'is_agent',
        numType: 'agent_release_day',
        label: '经销商审批',
        num: 0,
        checked: false,
      }],
    };
  },
  created() {
    this.id = this.$route.query.id;

    this.getTeacherAppointment();
    this.getData(1, this.pagination.pageSize);
  },
  methods: {
    checkChange(item) {
      item.num = 0;
    },
    numChanged(item) {
      item.checked = item.num > 0;
    },

    /**
     * 刷新当前页
     */
    onRefresh() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },

    /**
     * 获取数据
     * @param page
     * @param pageSize
     */
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        training_id: this.id,
        page: page,
        page_size: pageSize,
      };

      const data = await getTeacherList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '查询失败');
        return;
      }
      this.data = data.data;

      const pagination = data?.meta?.pagination || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },
    async getTeacherAppointment() {
      const data = await getTeacherAppointment({
        training_id: this.id,
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message);
        return;
      }

      const result = data?.data || {};
      this.appointmentId = result?.id || '';
      this.appointmentList.forEach((i) => {
        i.checked = !!result[i.value] || false;
        i.num = result[i.numType] || 0;
      });
    },

    /**
     * 删除
     * @param item
     */
    deleteItem(item) {
      this.$confirm({
        content: () => <div>确认删除该讲师？</div>,
        onOk: () => {
          this.confirmDeleteItem(item);
        },
      });
    },
    async confirmDeleteItem(item) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = new FormData();
      params.append('id', item.id);

      const data = await deleteTeacher(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data.message || '删除成功');

      this.onRefresh();
    },

    /**
     * 上下架
     * @param item
     */
    operate(item) {
      this.$confirm({
        content: () => `确认${+item.status === 1 ? '下架' : '上架'}该讲师？`,
        onOk: () => {
          this.confirmOperateTeacher(item);
        },
      });
    },
    async confirmOperateTeacher(item) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        status: +item.status === 1 ? 0 : 1,
        id: item.id,
      };

      const data = await operateTeacher(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || `${+item.status === 1 ? '下架' : '上架'}失败`);
        return;
      }
      this.$message.success(data.message || `${+item.status === 1 ? '下架' : '上架'}成功`);

      this.onRefresh();
    },

    /**
     * 保存
     * @returns {Promise<never>}
     */
    async save() {
      if (this.loading) {
        return Promise.reject('Loading');
      }
      this.loading = true;

      const params = new FormData();
      params.append('training_id', this.id);
      params.append('id', this.appointmentId);

      this.appointmentList.forEach((i) => {
        // params[i.value] = i.checked ? 1 : 0;
        // params[i.numType] = i.num;

        params.append(i.value, i.checked ? 1 : 0);
        params.append(i.numType, i.num);
      });

      const data = await saveTeacherAppointment(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return Promise.reject(data);
      }
      this.$message.success(data.message || '保存成功');
      Promise.resolve(data);
    },

    /**
     * 保存并下一步
     */
    saveAndNext() {
      this.save().then(() => {
        this.next();
      });
    },
    next() {
      const paths = [
        '',
        '/index/offline/config/classConfiguration',
        '/index/offline/config/checkWorkAttendanceConfiguration',
      ];
      this.$router.replace({
        path: paths[this.trainingType],
        query: {
          id: this.id,
        },
      });
    },

    openLayer(data) {
      this.$refs.layer.openMe(data);
    },

    addTeacher() {
      if (this.$refs.addTeacherModalRef?.show) {
        this.$refs.addTeacherModalRef.show({});
      }
    },
    editTeacher(item, data) {
      if (this.$refs.addTeacherModalRef?.show) {
        this.$refs.addTeacherModalRef.show({
          ...(item || {}),
          teacherIds: (data || []).filter(
            (i) => i.teacher_id !== item.teacher_id,
          ).map((i) => i.teacher_id).join(','),
        });
      }
    },

    teacherSaved() {
      this.getData(1, this.pagination.pageSize);
    },
  },
};
</script>

<style scoped lang="scss">
.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.w50 {
  width: 50px;
}
</style>
