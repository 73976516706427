<template>
  <div class="flex flex-start">
    <span class="mr-10">DMS人员信息表：</span>
    <selection-width-delete v-model="peopleId"
                            :options="peopleList"
                            :allow-delete="isAdmin"
                            @select="selectPeople"
                            @delete-item="deletePeople"/>
    <a-button v-if="isAdmin" class="ml-20" @click="showAddPeopleModal">添加新表</a-button>

    <add-people-modal ref="addPeopleModalRef"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import AddPeopleModal from './AddPeopleModal';
import SelectionWidthDelete from './SelectionWidthDelete';
import { deleteParticipationTask } from '@/api/statistics/participation/Index';

export default {
  name: 'SelectPeople',
  props: ['onlySelection'],
  components: { AddPeopleModal, SelectionWidthDelete },
  computed: {
    ...mapState({
      peopleList: state => state.StatisticsParticipation.peopleList,
      peopleListId: state => state.StatisticsParticipation.query?.peopleListId,
      isAdmin: state => !!state.User.isAdmin,
    }),
  },
  watch: {
    peopleListId: {
      immediate: true,
      handler(newValue) {
        if (!this.onlySelection) {
          this.peopleId = newValue;
        }
      },
    },
  },
  data() {
    return {
      peopleId: undefined,
    };
  },
  created() {
    if (!this.peopleList?.length) {
      this.$store.dispatch('StatisticsParticipation/getParticipationPeopleList');
    }
  },
  methods: {
    ...mapMutations({
      setQuery(commit, query) {
        commit('StatisticsParticipation/setQuery', query);
      },
    }),

    selectPeople() {
      if (!this.onlySelection) {
        this.setQuery({
          peopleListId: this.peopleId,
        });
      }
      this.$emit('change', this.peopleId);
    },

    async deletePeople(ev) {
      const data = await deleteParticipationTask(ev?.id);
      if (!data || data?.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.$store.dispatch('StatisticsParticipation/getParticipationPeopleList');
    },

    showAddPeopleModal() {
      if (this.$refs?.addPeopleModalRef?.show) {
        this.$refs.addPeopleModalRef.show();
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
