<template>
  <div class="gant-wrap">
    <div class="search">
      <a-form layout="inline" class="search-form">
        <a-form-item label="业务名称：" class="form-item">
          <a-input v-model="form.keywords"
                   style="width: 237px;"
                   :allow-clear="true"
                   placeholder="请输入业务名称"/>
        </a-form-item>
        <a-form-item label="选择业务：" class="form-item">
          <SelectTrainingCategories v-model="form.category_ids"/>
        </a-form-item>
        <a-form-item label="经销商：" class="form-item">
          <app-select-org v-model="form.org_ids" :data="orgList" width="362px"/>
        </a-form-item>
        <a-form-item label="选择日期:" class="form-item">
          <a-range-picker v-model="form.range"
                          :allow-clear="false"
                          style="width: 237px;"
                          @change="onChange">
            <a-icon slot="suffixIcon" type="schedule"/>
          </a-range-picker>
        </a-form-item>
        <a-form-item label="审批状态:" class="form-item">
          <a-select v-model="form.status" style="width: 237px;">
            <a-select-option key="" value="">不限</a-select-option>
            <a-select-option key="0" value="0">待审批</a-select-option>
            <a-select-option key="1" value="1">已审批</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="form-item search-btn">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-item>
        <a-form-item class="form-item export-btn search-btn">
          <a-button type="primary" @click="exportExcel">
            导出当前日程表
          </a-button>
          <a-button class="back-day" @click="backToday">
            <a-icon slot="suffixIcon" type="schedule"/>
            回今天
          </a-button>
        </a-form-item>
      </a-form>
    </div>

    <GanChart ref="ganttChart"
              :active-type="activeType"
              :gant-data="trainData"
              :start-time="startTime"
              :end-time="endTime"
              :allOrgShown="!!form.is_all"
              class="gantt-wrap-inner"
              @change="changeType"
              @allOrgShownChanged="allOrgShownChanged"/>
  </div>
</template>
<script>
import moment from 'moment';
import dayjs from 'dayjs';

import { baseUrl } from '@/Config';
import {
  getDashboardTrain,
  getTrainList,
  getDashboardOrg,
  getDashboardTutor,
  getOrgList,
} from './api/api';
import rearrange from './assets/rearrange';

import GanChart from './components/GanttChart.vue';
import SelectTrainingCategories from './components/SelectTrainingCategories';

const defaultQuery = {
  keywords: '',
  training_ids: undefined,
  category_ids: undefined,
  org_ids: '',
  status: '',
  range: [moment().subtract(1, 'months'), moment().endOf('months')],
  start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  end_date: moment().endOf('months').format('YYYY-MM-DD'),
  is_all: 0,
};

export default {
  name: 'Index',
  components: {
    GanChart,
    SelectTrainingCategories,
  },
  data() {
    return {
      orgList: [],

      form: { ...defaultQuery },
      startTime: defaultQuery.start_date,
      endTime: defaultQuery.end_date,
      trainList: [],
      parent: 0,
      trainData: [],
      activeType: 1,
    };
  },
  created() {
    this.getOrgList();
    this.getTrainGanTutor();
  },
  mounted() {
    this.backToday();
  },
  methods: {
    changeType(val) {
      if (val === this.activeType) {
        return;
      }
      this.activeType = val;
      this.getData(val);
    },
    getData(val) {
      if (val === 1) {
        return this.getTrainGanTutor();
      }
      return this.getOrgGanData();
    },
    /** 获取业务kanban2 */
    async getTrainGanData() {
      try {
        const params = {
          ...this.form,
        };
        delete params.range;
        delete params.is_all;
        const { data } = await getDashboardTrain(params);
        this.handleData(data, 1, 0, [], 2);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject();
      }
    },
    /** 获取业务kanban1 */
    async getTrainGanTutor() {
      try {
        const params = {
          ...this.form,
        };
        delete params.range;
        delete params.is_all;
        const { data } = await getDashboardTutor(params);
        this.trainData = [];
        this.handleData(data, 2, 0);
        return this.getTrainGanData();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    allOrgShownChanged(evt) {
      this.form.is_all = evt ? 1 : 0;
      this.getOrgGanData();
    },
    /** 获取经销商看板 */
    async getOrgGanData() {
      try {
        const { data } = await getDashboardOrg({ ...this.form, range: null });
        this.trainData = [
          { isCheckbox: true, isStatic: true },
        ];
        this.handleData(data, 2, 0);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    /** 整理后台传入的数据
     * @param arr
     * @param type
     * @param parent
     * @param parentRowIds
     * @param filterType 过滤数据类型 1, 业务辅导， 2, 业务培训， 3, 经销商
     */
    handleData(arr, type, parent, parentRowIds = [], filterType) {
      this.startTime = this.form.start_date;
      this.endTime = this.form.end_date;

      if (!arr?.length) {
        return arr;
      }

      arr.forEach((e) => {
        e.parent = parent;
        e.checked = false;
        e.expanded = true;
        e.parentRowIds = [...parentRowIds];
        e.rowId = this.$getGlobalId(false);
        if (e.data && e.data.length !== 0) {
          if (type === 2) {
            if (e.data[0]?.color) {

              const tempList = rearrange([...(e.data || [])]);

              if (tempList?.length > 1) { // 返回的数据时间有重叠
                const expandOrgList = [];
                tempList.forEach((f, idx) => {
                  expandOrgList.push(({
                    ...e,
                    // 由于甘特图的一行有重叠日期，需要多行显示，此标志证明该行是多行显示的,
                    // 注意，与甘特图父级折叠时产生的换行显示标志区分 isCollapsedNext，
                    // 在折叠与展开过程中此标志保持不变，isCollapsedNext 行可能会被添加与删除
                    isRowOverflowNext: !!idx,
                    color: e.color || e.data[0].color,
                    rowId: this.$getGlobalId(false),
                    isLeaf: true,
                    gtArray: f.map(f => {
                      return {
                        id: f.id,
                        training_id: f.training_id,
                        stage_id: f.stage_id,
                        classes_id: filterType === 2 ? f.id : f.classes_id,
                        name: f.name ? f.name : (f.org.short_name ? f.org.short_name : f.org.name),
                        start: f.start_date + ' 00:00:00',
                        end: f.end_date + ' 23:59:59',
                        status: f.status, //辅导业务状态'1待机构审核,2待小区审核,3待大区审核,4待总部审
                        status_sub: f.status_sub, //辅导变更状态'1新增，2变更' 0时显示status
                        stage: f.stage, //不为0则是辅导业务
                        to_be_confirmed: f.to_be_confirmed,
                        orgid: f.orgid,
                        color: f.color,
                        book_type: f.book_type,
                        org_agree: f.org_agree,
                      };
                    }),
                  }));
                });
                e.data = expandOrgList;
                this.trainData.push(...expandOrgList);
              } else {
                e.gtArray = e.data.map(f => {
                  return {
                    id: f.id,
                    training_id: f.training_id,
                    stage_id: f.stage_id,
                    classes_id: filterType === 2 ? f.id : f.classes_id,
                    name: f.name ? f.name : (f.org.short_name ? f.org.short_name : f.org.name),
                    start: f.start_date + ' 00:00:00',
                    end: f.end_date + ' 23:59:59',
                    status: f.status, //辅导业务状态'1待机构审核,2待小区审核,3待大区审核,4待总部审
                    status_sub: f.status_sub, //辅导变更状态'1新增，2变更' 0时显示status
                    stage: f.stage, //不为0则是辅导业务
                    to_be_confirmed: f.to_be_confirmed,
                    orgid: f.orgid,
                    color: f.color,
                    book_type: f.book_type,
                    org_agree: f.org_agree,
                  };
                });
                e.isLeaf = true;
                e.color = e.data[0].color;
                this.trainData.push(e);
              }

            } else {
              this.trainData.push(e);
              this.handleData(e.data, type, parent + 1, [...parentRowIds, e.rowId], filterType);
            }
          } else {
            this.trainData.push(e);
            this.handleData(e.data, type, parent + 1, [...parentRowIds, e.rowId], filterType);
          }
        } else {
          if (type === 1) {
            e.isLeaf = true;
            e.gtArray = [{
              start: e.start_date + ' 00:00:00',
              end: e.end_date + ' 23:59:59',
              id: e.id,
              name: e.name,
              status: e.status,
              status_sub: e.status_sub,
              to_be_confirmed: e.to_be_confirmed,
              training_id: e.training_id,
              stage_id: e.stage_id,
              classes_id: filterType === 2 ? e.id : e.classes_id,
              orgid: e.orgid,
              color: e.color,
              book_type: e.book_type,
              org_agree: e.org_agree,
            }];
          }
          this.trainData.push(e);
        }
      });

      return arr;
    },
    /** 导出日程表 */
    exportExcel() {
      let url = `${baseUrl}/offline/admin/dashboard/downLoad?`;
      url += `type=${this.activeType === 1 ? 'training' : 'org'}&`;

      const params = { ...this.form };
      delete params.range;

      if (this.activeType === 1) {
        delete params.is_all;
      }

      for (let key in params) {
        url += `${key}=${params[key] ? params[key] : ''}&`;
      }
      // {TODO: 经销商甘特图缺少一个 is_all字段 忘记加了 只有经销商用}
      window.open(`${url}auth_token=${this.$store.state.User.token}`);
    },
    /** 回到今天 */
    async backToday() {
      if (
        this.form.range?.[0]?.isAfter(moment(), 'days')
        || this.form.range?.[1]?.isBefore(moment(), 'days')
      ) {
        this.form.range = [...defaultQuery.range];
        this.form.start_date = this.form.range?.[0]?.format('YYYY-MM-DD') || '';
        this.form.end_date = this.form.range?.[1]?.format('YYYY-MM-DD') || '';
        this.startTime = defaultQuery.start_date;
        this.endTime = defaultQuery.end_date;
        await this.getData(this.activeType);
      }
      setTimeout(() => {
        if (!this.$refs.ganttChart?.scrollToTime) {
          return;
        }
        this.$refs.ganttChart.scrollToTime(dayjs().format('YYYY-MM-DD'));
      }, 0);
    },
    /** 时间选择 */
    onChange(date, dateString) {
      this.form.start_date = dateString[0] || '';
      this.form.end_date = dateString[1] || '';
    },
    /** 获取业务列表 */
    async getTrainList() {
      try {
        const { data } = await getTrainList({ page: 1 });
        this.trainList = data;
      } catch (error) {
        console.log(error);
      }
    },
    /** 查询 */
    handleSearch() {
      this.getData(this.activeType);
    },
    /** 重置 */
    handleReset() {
      this.form = { ...defaultQuery };
      this.handleSearch();
    },

    async getOrgList() {
      const data = await getOrgList();
      this.orgList = data?.data || [];
    },
  },
};
</script>

<style scoped lang="scss">
.gant-wrap {
  width: 1200px;
  height: 100%;
  margin: auto;
  padding: 10px 0;
  background-color: transparent;
}

@media screen and (min-width: 1280px) and (max-width: 2560px) {
  .gant-wrap {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 2560px) {
  .gant-wrap {
    width: 2560px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.search {
  margin-bottom: 10px;
  padding: 16px 20px 6px;
  border: 1px solid rgba(81, 198, 244, 0.29);
  border-radius: 2px;
  background: #FFFFFF;
  box-shadow: inset 0 0 0 0 rgba(19, 37, 85, 0.42);

  .search-form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .export-btn {
      display: flex;
      margin-left: auto;

      .back-day {
        width: 95px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #334FA0;
        border-radius: 4px;
        border: 1px solid #334FA0;
        margin: 0 26px 0 20px;
        cursor: pointer;
      }
    }
  }
}

.form-item {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

.gantt-wrap-inner {
  //height: calc(100% - 185px);
  height: calc(100% - 200px);
  background-color: transparent;
}

::v-deep .search-form .ant-form-item-label {
  display: block;
  line-height: 28px;
  text-align: left;
}

::v-deep .search-btn .ant-btn-primary {
  background: linear-gradient(90deg, #334FA0 0%, #3C85B8 100%);
  border-radius: 4px;
  border-color: transparent;
  outline: none;
}

::v-deep .search-btn .ant-btn-primary:focus {
  border-color: transparent;
}
</style>
