<template>
  <div class="ph-20">
    <div class="flex flex-end mb-20 pt-10 right-top-btn-group">
      <a-button @click="onCreate">添加讲师</a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="table small-cell-table">

      <template slot="look_at_data" slot-scope="text, record">
        <div v-for="(item) in record.look_at_data" :key="item.id">{{ item.title }}</div>
      </template>

      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button type="link" @click="onDelete(record)">删除</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <EditTeacherModal ref="editTeacherModalRef" @closed="editTeacherModalClosed"/>
  </div>
</template>


<script>
import {
  getTeacherList,
  deleteTeacher,
} from './api';

import Common from '@/utils/Common';

import Pagination, { getPagination } from '@components/Pagination';
import EditTeacherModal from './components/EditTeacherModal';

const columns = [
  {
    width: '100px',
    align: 'center',
    title: '讲师',
    dataIndex: 'created_user_data.truename',
    key: 'created_user_data.truename',
  },
  {
    width: '100px',
    align: 'center',
    title: '用户名',
    dataIndex: 'created_user_data.username',
    key: 'created_user_data.username',
  },
  {
    width: '150px',
    align: 'center',
    title: '岗位',
    dataIndex: 'created_user_data.postname',
    key: 'created_user_data.postname',
  },
  {
    width: '200px',
    align: 'center',
    title: '组织机构',
    dataIndex: 'created_user_data.orgname',
    key: 'created_user_data.orgname',
  },
  {
    width: '100px',
    align: 'center',
    title: '可看菜单',
    dataIndex: 'look_at_data',
    key: 'look_at_data',
    scopedSlots: { customRender: 'look_at_data' },
  },
  {
    width: '120px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
    EditTeacherModal,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onRefresh() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        page,
        per_page: pageSize,
      };

      const data = await getTeacherList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    onCreate() {
      if (this.$refs.editTeacherModalRef?.show) {
        this.$refs.editTeacherModalRef.show();
      }
    },
    onEdit({ id } = {}) {
      if (this.$refs.editTeacherModalRef?.show) {
        this.$refs.editTeacherModalRef.show({ id });
      }
    },
    editTeacherModalClosed(evt) {
      if (evt?.created) {
        this.onSearch();
      } else if (evt?.saved) {
        this.onRefresh();
      }
    },

    onDelete(record) {
      this.$confirm({
        content: `确认删除该讲师？`,
        onOk: () => this.confirmDelete(record),
      });
    },
    async confirmDelete({ id }) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteTeacher(id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.onRefresh();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
