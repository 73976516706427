<template>
  <div class="pd20">
    <div v-for="item in datas" :key="item.post_id">
      <div class="flex-box ptb10">
        <CommonTitle>{{ item.title }}</CommonTitle>
        <a-button @click="openLayer(item.post_id)" type="primary">
          添加预习内容
        </a-button>
      </div>
      <a-table :columns="columns" :data-source="item.list" bordered>
        <template slot="action" slot-scope="text, record">
          <a @click="deleteData(record)">删除</a>
        </template>
      </a-table>
    </div>
    <saveButtons
      ref="saveButtons"
      @saveAndNext="saveAndNext"
      :show-save="false"
      next-text="下一步"
    ></saveButtons>
    <addPreviewContentLayer
      ref="layer"
      @success="getData"
    ></addPreviewContentLayer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { previewContentsList, delPreviewContents } from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';

export default {
  name: 'previewContentsConfiguration',
  mixins: [ConfigMixin],
  components: {
    CommonTitle: () => import('./components/commonTitle'),
    saveButtons: () => import('./components/saveButtons'),
    addPreviewContentLayer: () => import('./components/addPreviewContentLayer'),
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
      isAppoint: state => state.Offline.isAppoint,
    }),
  },
  data() {
    return {
      columns: [
        {
          title: '类型',
          dataIndex: 'type_txt',
          scopedSlots: { customRender: 'type_txt' },
        },
        {
          title: '名称',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      datas: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    openLayer(post_id) {
      this.$refs.layer.openMe(post_id);
    },
    async getData() {
      this.loading = true;
      const data = await previewContentsList(this.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      this.datas = data.data;
    },
    async delApiData(v) {
      this.loading = true;
      const data = await delPreviewContents({ id: v.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      this.$message.success(data.message);
      this.getData();
    },
    handleSubmit() {
      let path = 'signUpConfiguration';
      if (this.isAppoint) {
        if (this.trainingType === 1) {
          path = 'classConfiguration';
        } else {
          path = 'instructorAppointment';
        }
      }
      this.goNextStep(path);
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.ptb10 {
  padding: 20px 0;
}
</style>
