<template>
  <a-modal :visible="shown"
           :title="title"
           :mask="true"
           :mask-closable="true"
           :confirm-loading="loading"
           ok-text="确定上传"
           @ok="save"
           @cancel="close">
    <a-form :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            :colon="false">
      <a-form-item label=" ">
        <a-upload name="file"
                  :disabled="loading"
                  :accept="accept"
                  :file-list="fileList"
                  :multiple="false"
                  :remove="removeFile"
                  :before-upload="beforeUpload">
          <a-button>选择本地文件</a-button>
        </a-upload>
      </a-form-item>

      <a-form-item v-if="templateUrl" label=" ">
        <span>点击</span>
        <a :href="templateUrl" target="_blank">此处</a>
        <span>下载模版</span>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'ImportFileModal',
  data() {
    return {
      loading: false,
      shown: false,

      title: '',
      templateUrl: '',
      accept: '',
      fileList: [],
      submit: undefined,
    };
  },
  methods: {
    show({ templateUrl, title, accept, submit } = {}) {
      this.title = title || '上传文件';
      this.templateUrl = templateUrl || '';
      this.accept = accept;
      this.submit = submit;

      this.shown = true;
    },
    close() {
      this.shown = false;
      this.clear();
    },
    clear() {
      this.templateUrl = '';
      this.accept = '';
      this.submit = undefined;
      this.fileList = [];
    },

    removeFile() {
      this.fileList = [];
      return true;
    },
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },

    async save() {
      if (this.loading || !this.fileList?.length || !this.submit) {
        return;
      }
      this.loading = true;
      const data = await this.submit(this.fileList[0]).finally(() => {
        this.loading = false;
      });

      if (data && !data.error_code) {
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
